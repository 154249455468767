import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import AdminEventsNavbar from '../AdminEventsNavbar';
import Switch from "react-switch";
import Select from 'react-select'
import { MdAdd, MdCancel, MdDelete } from 'react-icons/md';
import ShortTextComponent from './form/ShortTextComponent';
import LongTextComponent from './form/LongTextComponent';
import ListComponent from './form/ListComponent';
import SingleSelectionComponent from './form/SingleSelectionComponent';
import CheckboxComponent from './form/CheckboxComponent';
import { capitaliseFirstLetter } from '../../utils/helper';

export default function EventParticipantDetail() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()


  let event_id = new URLSearchParams(window.location.search).get("event_id")
  let selectedItem = new URLSearchParams(window.location.search).get("state") ? new URLSearchParams(window.location.search).get("state") : "general"
  let registration_id = new URLSearchParams(window.location.search).get("registration_id")

  const [eventData, setEventData] = useState({
    name: "",
    description: "",
    event_start_datetime: "",
    event_end_datetime: "",
    venue: "",
    price: "0",
    is_unlimited_capacity: "0",
    capacity: "100",
    has_accommodation: "0",
    has_transportation: "0",
    accommodation_id: "",
    transportation_id: "",
    registration_start_datetime: "",
    registration_end_datetime: "",
  })
  const [registrationData, setRegistrationData] = useState({})





  const fetchEventById = async () => {
    if (!event_id || event_id === "new") return
    let endpoint = `${config.endpoint}/events/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setEventData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const fetchEventByRegistrationId = async () => {
    if (!registration_id || registration_id === "new") return
    let endpoint = `${config.endpoint}/events/registration/id/${registration_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setRegistrationData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    fetchEventById()
    fetchEventByRegistrationId()
  }, [])


  const updateRegistrationById = async () => {
    let endpoint = `${config.endpoint}/events/registration/id/${registration_id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(registrationData)
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      fetchEventByRegistrationId()
    } else {
      notify({ type: "error", message: data.message })
    }
  }




  return (
    <div>
      <div className="flex flex-row items-start gap-2">
        <div className="w-full">
          <div className="card">
            <label htmlFor="" className="card-title">Registration Form</label>
            {registrationData.field && registrationData.field.map((item, index) => {
              return (
                <>
                  <div className="input-container">
                    <label htmlFor="" className="input-label">{item?.name}</label>
                    {item?.field_type === "text" && (
                      <ShortTextComponent
                        key={index}
                        item={item}
                        data={item?.answer}
                        type={item?.type}
                        state="answer"
                        onChange={(value) => {
                          let newData = { ...registrationData }
                          newData.field[index].answer = value
                          setRegistrationData(newData)
                        }}
                      />
                    )}
                    {item?.field_type === "textarea" && (
                      <LongTextComponent
                        key={index}
                        item={item}
                        data={item?.answer}
                        state="answer"
                        onChange={(value) => {
                          let newData = { ...registrationData }
                          newData.field[index].answer = value
                          setRegistrationData(newData)
                        }}
                      />
                    )}
                    {item?.field_type === "select" && (
                      <ListComponent
                        key={index}
                        item={item}
                        data={item?.answer}
                        state="answer"
                        field_options={item?.field_options}
                        onChange={(value) => {
                          let newData = { ...registrationData }
                          newData.field[index].answer = value
                          setRegistrationData(newData)
                        }}
                      />
                    )}
                    {item?.field_type === "radio" && (
                      <SingleSelectionComponent
                        key={index}
                        item={item}
                        data={item?.answer}
                        state="answer"
                        field_options={item?.field_options}
                        onChange={(value) => {
                          let newData = { ...registrationData }
                          newData.field[index].answer = value
                          setRegistrationData(newData)
                        }}
                      />
                    )}
                    {item?.field_type === "checkbox" && (
                      <CheckboxComponent
                        key={index}
                        item={item}
                        data={item?.answer}
                        state="answer"
                        field_options={item?.field_options}
                        onChange={(value) => {
                          let newData = { ...registrationData }
                          newData.field[index].answer = value
                          setRegistrationData(newData)
                        }}
                      />
                    )}
                  </div>
                </>
              )
            })}

            <div className="btn-grp">
              <button className="button"
                onClick={() => {
                  updateRegistrationById()
                }}
              >Save</button>
            </div>
          </div>
        </div>
        <div className="w-8/12">
          <div className="card">
            <label htmlFor="" className="card-title">Status</label>
            <div className="input-container">
              <label htmlFor="" className="input-label">Booking Status</label>
              <Select
                options={[
                  { value: "pending", label: "Pending" },
                  { value: "completed", label: "Completed" },
                  { value: "cancelled", label: "Cancelled" },
                  { value: "waitlisted", label: "Waitlisted" },
                ]}
                value={{ value: registrationData.status, label: capitaliseFirstLetter(registrationData.status) }}
                onChange={(e) => {
                  setRegistrationData({ ...registrationData, status: e.value })
                }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Payment Status</label>
              <Select
                options={[
                  { value: "0", label: "Unpaid" },
                  { value: "1", label: "Paid" },
                ]}
                value={{ value: registrationData.is_paid, label: registrationData.is_paid === "1" ? "Paid" : "Unpaid" }}
                onChange={(e) => {
                  if (e.value === "1") {
                    setRegistrationData({ ...registrationData, is_paid: e.value, status: "completed", payment_datetime: new Date() })
                  }else {
                    setRegistrationData({ ...registrationData, is_paid: e.value })
                  }
                }}
              />
            </div>
            {registrationData?.is_paid == 1&&<>
              <div className="input-container">
                <label htmlFor="" className="input-label">Payment Amount</label>
                <input type="text" className="text-input" value={registrationData.payment_amount} onChange={(e) => {
                  setRegistrationData({ ...registrationData, payment_amount: e.target.value })
                }} />
              </div>
            </>}
          </div>
          <div className="card mt-2">
            <label htmlFor="" className="card-title">Remarks</label>
            <div className="input-container">
              <label htmlFor="" className="input-label">Internal Remarks</label>
              <textarea className="text-input" value={registrationData.remarks} onChange={(e) => {
                setRegistrationData({ ...registrationData, remarks: e.target.value })
              }} />
            </div>
          </div>
        </div>
      </div>
      <pre>{JSON.stringify(registrationData, null, 2)}</pre>
    </div>
  )
}
