import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "../../layouts/AdminLayout";
import {
  useFetchAuthorisation,
  useFetchUserData
} from "../../utils/AppFunctions";
import { MdAdd } from "react-icons/md";
import config from "../../app-config";
import { tidyDate } from "../../utils/helper";

export default function LibraryPage() {
  const navigate = useNavigate();
  const { isAuthorised, userRole } = useFetchAuthorisation("user");
  let user_data = useFetchUserData();

  const [query, setQuery] = useState("")
  const [loading, setLoading] = useState(true)
  const [libraryData, setLibraryData] = useState([])

  useEffect(() => {
    fetchLibrary()
  }, [])

  const fetchLibrary = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/library"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setLibraryData(data.data)
    }
    setLoading(false)
  }

  return (
    <AdminLayout navSelected={"Library Items"}
      toolbar={
        <>
          <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
            <h1 className='medium'>Library</h1>
            <button
              className="button flex-row items-center flex gap-2"
              onClick={() => {
                navigate("/admin/library/details?library_id=new")
              }}
            >
              <MdAdd size={15} />
              Item
            </button>
          </div>
        </>
      }
    >
      <div className="column items-center justify-between w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>

      {loading ? (
        <div className="flex-row items-center flex justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {libraryData.map((library, index) => {
            if (!JSON.stringify(library).toLowerCase().includes(query.toLowerCase())) return null
            return (
              <div
                key={index}
                className="flex-row items-center flex justify-between w-100 card mt-10"
              >
                <div className="">

                  <h3>{library.title}</h3>
                  <h3 className='card-subtitle'>{library.subtitle}</h3>
                </div>
                <button
                  className="button"
                  onClick={() => {
                    navigate("/admin/library/details?library_id=" + library.id)
                  }}
                >
                  View
                </button>
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}