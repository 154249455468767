import React from 'react'
import config from '../app-config'
import { useState } from 'react';
import { useEffect } from 'react';

export default function MainHeader() {

  const [announcementBanner, setAnnouncementBanner] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isConnectOpen, setIsConnectOpen] = useState(false);
  const [isVisitOpen, setIsVisitOpen] = useState(false);

  const fetchAnnouncementBanner = async () => {
    let endpoint = config.endpoint + "/config/announcement";
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include"
    });
    let data = await response.json();
    if (data.status === 200) {
      setAnnouncementBanner(data.data);
      window.sessionStorage.setItem("announcementBanner", data.data);
    } else {
      setAnnouncementBanner("");
    }
  };

  useEffect(() => {
    let announcement = window.sessionStorage.getItem("announcementBanner");
    if (announcement) {
      setAnnouncementBanner(announcement);
    }
    fetchAnnouncementBanner();
  }, []);
  return (
    <>
      {announcementBanner && announcementBanner.length > 0 && (
        <div
          style={{
            padding: "10px 20px",
            textAlign: "center",
            backgroundColor: "#f8d7da",
            width: "100%",
          }}
        >
          <p style={{ margin: 0, fontSize: 14 }}>{announcementBanner}</p>
        </div>
      )}

      <div className="px-5 py-4 bg-white p-3">
        <img src={"/app/logo_teal.png"} alt="" style={{ objectFit: "contain", width: "auto", height: 100, }} />
        <p className="text-gray-400 text-xs">Your faithful gateway to digital church management</p>
      </div>
    </>
  )
}
