import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import { currency, tidyDate } from '../../utils/helper';

export default function TithingPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [tithingData, setTithingData] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchAllTithes = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/tithing/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include"
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setTithingData(response.data?.reverse())
    } else {
      notify({ message: "Unable to fetch tithes", type: "error" })
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchAllTithes()
  }, [])

  return (
    <AdminLayout
      navSelected={"Tithing"}
      toolbar={
        <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Tithings and Offeratory</h1>
          {/* <button
            className="button flex-row items-center flex gap-2"
            onClick={() => {
              navigate("/admin/ministry/details?ministry_id=new")
            }}
          >
            <MdAdd size={15} />
            Ministry
          </button> */}
        </div>
      }
    >

      {loading ? (
        <div className="flex-row items-center flex justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {tithingData.map((tithing, index) => {
            return (
              <div
                key={index}
                className="flex-row items-center flex justify-between w-100 card mt-10"
              >
                <div className="">
                  <h3>${currency(tithing.amount)}</h3>
                  <p className='small'>{tithing?.is_confirmed == 1 ? "Confirmed" : "Pending"} • {tithing?.is_confirmed == 1 &&tidyDate(tithing?.payment_datetime)}</p>
                </div>
                {/* <button
                  className="button"
                  onClick={() => {
                    navigate("/admin/tithing/details?plan_id=" + tithing.id)
                  }}
                >
                  View
                </button> */}
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}
