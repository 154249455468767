import React from "react";

export default function LongTextComponent({
  data,
  onChange,
  state,
  type,
  readonly,
}) {
  return (
    <>
      {state === "answer" && (
        <textarea
          type={type || "text"}
          className="textarea-input"
          style={{ width: "100%" }}
          value={data}
          readOnly={readonly}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </>
  );
}
