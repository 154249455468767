import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  useFetchAuthorisation,
  authorisationConverter,
  useFetchUserData,
} from "../../utils/AppFunctions";
import config from "../../app-config";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "../../layouts/AdminLayout";
import notify from "../../utils/IANotification";

export default function ContactFormPage() {
  const navigate = useNavigate();
  const { isAuthorised, userRole } = useFetchAuthorisation("user");
  let user_data = useFetchUserData();
  let form_id = new URLSearchParams(window.location.search).get("form_id");
  const [contactForm, setContactForm] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchContactFormById = async () => {
    setLoading(true);
    let endpoint = `${config.endpoint}/contact/id/${form_id}`;
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    let data = await response.json();
    if (data.status === 200) {
      setContactForm(data.data);
    } else {
      notify({ message: data.message, type: "error" });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (form_id) {
      fetchContactFormById();
    }
  }, []);

  return (
    <AdminLayout navSelected={"Contact Form"}>
      <div className="flex flex-row items-start w-8/12">
        <div className="card">
          <p className="card-title">Contact Form</p>
          <div className="input-container">
            <label htmlFor="" className="input-label">Name</label>
            <input type="text" className="text-input" disabled value={contactForm.name} onChange={(e) => setContactForm({ ...contactForm, name: e.target.value })} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Email</label>
            <input type="text" className="text-input" disabled value={contactForm.email} onChange={(e) => setContactForm({ ...contactForm, email: e.target.value })} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Phone</label>
            <input type="text" className="text-input" disabled value={contactForm.contact_number} onChange={(e) => setContactForm({ ...contactForm, contact_number: e.target.value })} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Message</label>
            <textarea className="textarea-input" disabled value={contactForm.message} onChange={(e) => setContactForm({ ...contactForm, message: e.target.value })} />
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}