import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import AdminEventsNavbar from '../AdminEventsNavbar';
import Switch from "react-switch";
import Select from 'react-select'
import { MdAdd, MdCancel, MdDelete } from 'react-icons/md';
import ShortTextComponent from './form/ShortTextComponent';
import LongTextComponent from './form/LongTextComponent';
import ListComponent from './form/ListComponent';
import SingleSelectionComponent from './form/SingleSelectionComponent';
import CheckboxComponent from './form/CheckboxComponent';
import Table from '../app-components/Table';
import { capitaliseFirstLetter } from '../../utils/helper';

export default function EventParticipant() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()


  let event_id = new URLSearchParams(window.location.search).get("event_id")
  let selectedItem = new URLSearchParams(window.location.search).get("state") ? new URLSearchParams(window.location.search).get("state") : "general"

  const [eventData, setEventData] = useState({
    name: "",
    description: "",
    event_start_datetime: "",
    event_end_datetime: "",
    venue: "",
    price: "0",
    is_unlimited_capacity: "0",
    capacity: "100",
    has_accommodation: "0",
    has_transportation: "0",
    accommodation_id: "",
    transportation_id: "",
    registration_start_datetime: "",
    registration_end_datetime: "",
  })

  const [accommodations, setAccommodations] = useState([]) //
  const [selectedAccommodation, setSelectedAccommodation] = useState(null)
  const [formQuestions, setFormQuestions] = useState([])
  const [mandartoryFields, setMandartoryFields] = useState([])
  const [participantListData, setParticipantListData] = useState([])




  const fetchEventById = async () => {
    if (!event_id || event_id === "new") return
    let endpoint = `${config.endpoint}/events/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setEventData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }


  const fetchEventParticipants = async () => {
    let endpoint = `${config.endpoint}/events/participants/event/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setParticipantListData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    fetchEventById()
    fetchEventParticipants()
  }, [])

  let table_columns = [
    {
      header: "Name",
      data: (row) => row?.field?.find((field) => field.smart_tag === "fullname")?.answer
    },
    {
      header: "Email",
      data: (row) => row?.field?.find((field) => field.smart_tag === "email")?.answer
    },
    {
      header: "Payment Status",
      data: (row) => row?.is_paid ? "Paid" : "Unpaid"
    },
    {
      header: "Registration Status",
      data: (row) => capitaliseFirstLetter(row?.status)
    },
  ]


  return (
    <div>
      <Table
        columns={table_columns}
        data={participantListData}
        actions={(row) => {
          return (
            <>
              <button className="text-xs hover:underline" onClick={() => {
                navigate("/admin/events/details?state=participants_detail&event_id=" + event_id + "&registration_id=" + row.id, { replace: false })
              }}>View</button>
            </>
          )
        }}
      />
      {/* {participantListData?.length > 0 && participantListData.map((participant, index) => {
        let name = participant?.field?.find((field) => field.smart_tag === "fullname")?.answer
        let email = participant?.field?.find((field) => field.smart_tag === "email")?.answer
        return (
          <div className="card mt-10 row justify-between">
            <div>
              <p className="card-title">{name}</p>
              <p className="card-subtitle">{email}</p>
            </div>
            <button className="button"
              onClick={() => {
                navigate("/admin/events/details?state=participants_detail&event_id=" + event_id + "&registration_id=" + participant.id, { replace: false })
              }}
            >View</button>
          </div>
        )
      })} */}
      {/* <pre>{JSON.stringify(participantListData, null, 2)}</pre> */}
    </div>
  )
}
