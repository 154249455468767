// CONST VALUES
const DAYS = [
    { value: "0", label: "Sunday" },
    { value: "1", label: "Monday" },
    { value: "2", label: "Tuesday" },
    { value: "3", label: "Wednesday" },
    { value: "4", label: "Thursday" },
    { value: "5", label: "Friday" },
    { value: "6", label: "Saturday" },
]



const tidyDate = (date) => {
    // if (!date?.includes("Z")) {
    //     date = date + "Z"
    // }

    // Convert From Unix Timestamp

    if (!isNaN(date)) {
        let seconds = date
        date = new Date(date * 1000)
    }

    let day = new Date(date).getDate()
    let month = new Date(date).getMonth() + 1
    let year = new Date(date).getFullYear()
    let hour = new Date(date).getHours()
    let minute = new Date(date).getMinutes()

    if (day < 10) {
        day = "0" + day
    }

    if (month < 10) {
        month = "0" + month
    }

    if (hour < 10) {
        hour = "0" + hour
    }

    if (minute < 10) {
        minute = "0" + minute
    }

    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    return `${day} ${MONTHS[month - 1]} ${year} ${hour}:${minute}`
}



const tidyDateRaw = (date) => {
    // if (!date?.includes("Z")) {
    //     date = date + "Z"
    // }

    // Convert From Unix Timestamp

    if (!isNaN(date)) {
        let seconds = date
        date = new Date(date * 1000)
    }

    let day = new Date(date).getDate()
    let month = new Date(date).getMonth() + 1
    let year = new Date(date).getFullYear()
    let hour = new Date(date).getHours()
    let minute = new Date(date).getMinutes()

    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    if (day < 10) {
        day = "0" + day
    }

    if (month < 10) {
        month = "0" + month
    }

    if (hour < 10) {
        hour = "0" + hour
    }

    if (minute < 10) {
        minute = "0" + minute
    }

    return {
        day: day,
        month: MONTHS[month - 1],
        year: year,
        hour: hour,
        minute: minute
    }

}

const currency = (amount) => {
    amount = Math.round((amount) * 100) / 100

    if (amount.toString().includes(".")) {
        amount = amount.toFixed(2)
    }

    // add commas
    amount = amount.toLocaleString()

    return amount
}

const isMobile = (width) => {
    if (width < 700) {
        return true
    } else {
        return false
    }
}

const getAgeByDate = (date) => {
    let today = new Date()
    let birthDate = new Date(date)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}

const capitaliseFirstLetter = (string) => {
    if (!string) return ""
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const tidyFrequencyName = (frequency) => {
    // Count the number of days, weeks, or months
    let frequencyName = ""
    if (frequency === "1") {
        frequencyName = "Day"
    } else if (frequency === "7") {
        frequencyName = "Week"
    } else if (frequency === "30") {
        frequencyName = "Month"
    } else {
        frequencyName = "Custom"
    }
    return `${frequency} ${frequencyName}`
}


const getMobileOS = () => {
    var userAgent = navigator.userAgent
    console.log(`User Agent: ${userAgent}`)

    // Android detection
    if (/android/i.test(userAgent)) {
        return "android"
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios"
    }

    // Windows detection
    if (/Windows NT/i.test(userAgent)) {
        return "windows"
    }

    // MacOS detection
    if (/Macintosh|Mac OS X/i.test(userAgent)) {
        return "macos"
    }

    // Linux detection
    if (/Linux/i.test(userAgent)) {
        return "linux"
    }
}


module.exports = {
    tidyDate,
    tidyDateRaw,
    currency,
    isMobile,
    getAgeByDate,
    capitaliseFirstLetter,
    tidyFrequencyName,
    DAYS,
    getMobileOS
}
