import React from 'react'
import { useNavigate } from 'react-router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AdminNavbar from '../components/AdminNavbar'
import { useFetchAuthorisation } from '../utils/AppFunctions'

export default function UserLayout({ children, navSelected, }) {
    const navigate = useNavigate()
    const { isAuthorised, userRole } = useFetchAuthorisation("user")

    return (
        <div style={{ width: "100vw", }}>
            <ToastContainer />
            <div className='row' style={{ width: "100%" }}>
                <AdminNavbar selected={navSelected} />
                <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll", }}>
                        <div className="container">
                            <div className="inner-container">
                                {children}
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}
