import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import { tidyDate } from '../../utils/helper';
import { MdAdd } from 'react-icons/md';

export default function AdminCafePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()


  const [breakfastData, setBreakfastData] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")

  const fetchBreakfastInformation = async () => {
    setLoading(true)
    let endpoint = `${config.endpoint}/cafe/breakfast`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      // sort by datetime
      let temp = data.data.sort((a, b) => {
        return new Date(b.datetime) - new Date(a.datetime)
      })
      setBreakfastData(temp)
    } else {
      notify({ type: "error", message: data.message })
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchBreakfastInformation()
  }, [])

  return (
    <AdminLayout
      navSelected={"Cafe"}
      toolbar={
        <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Cafe</h1>
          <button
            className="button flex-row items-center flex gap-2"
            onClick={() => {
              navigate("/admin/cafe/details?breakfast_id=new")
            }}
          >
            <MdAdd size={15} />
            Breakfast
          </button>
        </div>
      }
    >
      {/* <div className="flex-row items-center flex justify-between w-100">
        <h1 className='medium'>Breakfast Information</h1>
        <button
          className="button flex-row items-center flex gap-2"
          onClick={() => {
            navigate("/admin/cafe/details?breakfast_id=new")
          }}
        >
          <MdAdd size={15} />
          Breakfast
        </button>
      </div> */}

      <div className="column items-center justify-between w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>

      {loading ? (
        <div className="flex-row items-center flex justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {breakfastData.map((data, index) => {
            if (!JSON.stringify(data)?.toLowerCase().includes(query.toLowerCase())) return null
            return (
              <div
                key={index}
                className="flex-row items-center flex justify-between w-100 card mt-10"
              >
                <div className="">
                  <h3>{data?.name}</h3>
                  <h3>{tidyDate(data?.datetime)}</h3>
                </div>
                <button
                  className="button"
                  onClick={() => {
                    navigate("/admin/cafe/details?breakfast_id=" + data.id)
                  }}
                >
                  View
                </button>
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}
