import React from "react";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import UserLayout from "../../layouts/UserLayout";
import {
  useFetchAuthorisation,
  useFetchUserData
} from "../../utils/AppFunctions";

export default function MemberHomePage() {
  const navigate = useNavigate();
  const { isAuthorised, userRole } = useFetchAuthorisation("user");
  let user_data = useFetchUserData();

  return (
    <UserLayout navSelected={"Home"}>
      
    </UserLayout>
  )
}