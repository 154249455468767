import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import config from '../../app-config'
import notify from '../../utils/IANotification'
import Table from '../app-components/Table'
import { tidyDate } from '../../utils/helper'

export default function EventCheckIn() {
  const navigate = useNavigate()
  const event_id = new URLSearchParams(window.location.search).get('event_id')
  const [checkInSessionData, setCheckInSessionData] = useState([])

  useEffect(() => {
    fetchCheckInSessions()
  }, [])

  const fetchCheckInSessions = async () => {
    if (!event_id || event_id === "new") return
    let endpoint = `${config.endpoint}/events/checkin/session/event/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setCheckInSessionData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const table_columns = [
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Start Date",
      data: (row) => `${tidyDate(row.start_datetime)}`,
    },
    {
      header: "End Date",
      data: (row) => `${tidyDate(row.end_datetime)}`,
    },
  ]


  return (
    <div>
      <div className="btn-grp">
        <button className="button flex flex-row items-center gap-2"
          onClick={() => navigate(`/admin/events/details?state=check-in_detail&event_id=${event_id}&session_id=new`)}
        >
          <MdAdd />
          <span>Add Session</span>
        </button>
      </div>
      <Table
        className="mt-4"
        data={checkInSessionData}
        columns={table_columns}
        actions={(row) => {
          return (
            <>
              <button className="text-xs hover:underline"
                onClick={() => navigate(`/admin/events/details?state=check-in_detail&event_id=${event_id}&session_id=${row.id}`)}
              >
                View
              </button>
            </>
          )
        }}
      />

    </div>
  )
}
