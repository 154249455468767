import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import { MdAdd } from 'react-icons/md';
import { tidyDate, tidyDateRaw } from '../../utils/helper';

export default function SpecialServicesPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [specialServicesData, setSpecialServicesData] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")

  useEffect(() => {
    fetchSpecialServices()
  }, [])

  const fetchSpecialServices = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/services/special/admin"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      let service_data = data.data
      service_data.sort((a, b) => {
        return new Date(b.start_datetime) - new Date(a.start_datetime)
      })
      setSpecialServicesData(service_data)
    }
    setLoading(false)
  }


  return (
    <AdminLayout
      navSelected={"Special Services"}
      toolbar={
        <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Special Services</h1>
          <button
            className="button flex-row items-center flex gap-2"
            onClick={() => {
              navigate("/admin/special/services/details?special_service_id=new")
            }}
          >
            <MdAdd size={15} />
            Service
          </button>
        </div>
      }
    >
     
      <div className="column items-center justify-between w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>

      {loading ? (
        <div className="flex-row items-center flex justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {specialServicesData.map((sepcial_service, index) => {
            if (!JSON.stringify(sepcial_service).toLowerCase().includes(query.toLowerCase())) return null
            return (
              <div
                key={index}
                className="flex-row items-center flex justify-between w-100 card mt-10"
              >
                <div className="">
                  <p className='small'>{sepcial_service.is_published == 1 ? "Published" : "Draft"}</p>
                  <h3>{sepcial_service.name}</h3>
                  <h3 className='card-subtitle'>{tidyDate(sepcial_service.start_datetime)} - {tidyDate(sepcial_service.end_datetime)}</h3>
                </div>
                <button
                  className="button"
                  onClick={() => {
                    navigate("/admin/special/services/details?special_service_id=" + sepcial_service.id)
                  }}
                >
                  View
                </button>
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}
