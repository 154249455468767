import React, { useState, useEffect } from "react";
import "../globals.css";
import config from "../app-config";
import { FaClock, FaEnvelope, FaPhone } from "react-icons/fa";
import { MdAlarm, MdMail, MdPhone, MdTimer } from "react-icons/md";
import { Link } from "react-router-dom";

export default function Header({ hasScrolled }) {
  const [announcementBanner, setAnnouncementBanner] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isConnectOpen, setIsConnectOpen] = useState(false);
  const [isVisitOpen, setIsVisitOpen] = useState(false);

  const fetchAnnouncementBanner = async () => {
    let endpoint = config.endpoint + "/config/announcement";
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    if (data.status === 200) {
      setAnnouncementBanner(data.data);
      window.sessionStorage.setItem("announcementBanner", data.data);
    } else {
      setAnnouncementBanner("");
    }
  };

  useEffect(() => {
    let announcement = window.sessionStorage.getItem("announcementBanner");
    if (announcement) {
      setAnnouncementBanner(announcement);
    }
    fetchAnnouncementBanner();
  }, []);

  return (
    <>
      <div
        style={{
          position: "sticky",
          top: "0",
          zIndex: 10,
        }}
      >
        <div
          style={{
            borderBottom: "1px solid gray",
            width: "100%",
            backgroundColor: "#0094b3",
            display: "flex",
            flexDirection: "column",
          }}
          className="justifyCenter items-center"
        >
          {announcementBanner && announcementBanner.length > 0 && (
            <div
              style={{
                padding: "10px 20px",
                textAlign: "center",
                backgroundColor: "lightgrey",
                width: "100%",
              }}
            >
              <p style={{ margin: 0, fontSize: 14 }}>{announcementBanner}</p>
            </div>
          )}

          <div
            className="gap-4 tooltip-bar"
            style={{
              flexDirection: "row",
              color: "lightgrey",
              width: "auto",
              textDecoration: "none",
              paddingTop: hasScrolled ? "40px" : 0,
              zIndex: 0,
              opacity: hasScrolled ? 1 : 0,
              height: hasScrolled ? "auto" : 0,
            }}
          >
            <p
              className="tooltip"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
              }}
              onClick={() => window.open("mailto:office@bmc.org.sg", "_blank")}
            >
              <MdMail size={20} />
              CONTACT
              <span className="tooltiptext">office@bmc.org.sg</span>
            </p>
            <span>|</span>
            <p
              className="tooltip"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
              to=""
            >
              <MdTimer size={20} />
              SERVICE TIMES
              <span className="tooltiptext">
                Service Times | English: 8.30am & 11am (Sun) | Chinese: 11am
                (Sun) | Children: 8.30am & 11am (Sun) | Youth: 10.30am (Sun)
              </span>
            </p>
            <span>|</span>
            <p
              className="tooltip"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
              }}
              to=""
              onClick={() => window.open("tel:+65 6448 4215", "_blank")}
            >
              <MdPhone size={20} />
              +65 6448 4215
              <span className="tooltiptext">+65 6448 4215</span>
            </p>
          </div>

          {/* Desktop Nav */}
          <div
            className="desktop-nav"
            style={{
              flexDirection: "row",
              width: "100%",
              padding: 15,
            }}
          >
            <img
              src="/logoWhite.png"
              alt="logo"
              style={{ width: "170px", height: "80px", objectFit: "contain" }}
            />
            <div
              className="header flex-row items-center flex justifyCenter gap-30"
              style={{
                padding: 10,
                backgroundColor: "#0094b3",
                color: "#EEEEEE",
                width: "100%",
              }}
            >
              <div className="flex-row flex gap-30">
                <nav className="" style={{ color: "white", fontSize: "18px" }}>
                  <ul className="flex-row flex gap-30" style={{ listStyle: "none" }}>
                    <li>
                      <Link
                        to=".."
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        Home
                      </Link>
                    </li>

                    <li className="nav-tooltip">
                      <Link
                        className="flex-row flex"
                        to="/about"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        About Us
                        <img src="/caret.svg" alt="caret dropdown" width={20} />
                        <ul
                          className="nav-tooltiptext"
                          style={{
                            listStyle: "none",
                            fontSize: 16,
                            padding: 10,
                          }}
                        >
                          <li>
                            <Link
                              to="/about"
                              style={{
                                color: "grey",
                                textDecoration: "none",
                              }}
                            >
                              Who We Are
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/our-pastors-staff"
                              style={{
                                color: "grey",
                                textDecoration: "none",
                              }}
                            >
                              Our Pastors & Staff
                            </Link>
                          </li>
                        </ul>
                      </Link>
                    </li>
                    <li className="nav-tooltip">
                      <Link
                        className="flex-row flex"
                        to="/ministries"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        Connect
                        <img src="/caret.svg" alt="caret dropdown" width={20} />
                        <ul
                          className="nav-tooltiptext"
                          style={{
                            listStyle: "none",
                            fontSize: 16,
                            padding: 10,
                          }}
                        >
                          <li>
                            <Link
                              to="/ministries"
                              style={{
                                color: "grey",
                                textDecoration: "none",
                              }}
                            >
                              All Ministries
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="home"
                              style={{
                                color: "grey",
                                textDecoration: "none",
                              }}
                            >
                              COSC
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="home"
                              style={{
                                color: "grey",
                                textDecoration: "none",
                              }}
                            >
                              Disciple Groups
                            </Link>
                          </li>
                        </ul>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/missions-release"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        Mission Release
                      </Link>
                    </li>
                    <li className="nav-tooltip">
                      <Link
                        className="flex-row flex"
                        to="/getting-here"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        Visit Us
                        <img src="/caret.svg" alt="caret dropdown" width={20} />
                        <ul
                          className="nav-tooltiptext"
                          style={{
                            listStyle: "none",
                            fontSize: 16,
                            padding: 10,
                          }}
                        >
                          <li>
                            <Link
                              to="/getting-here"
                              style={{
                                color: "grey",
                                textDecoration: "none",
                              }}
                            >
                              Getting to BMC
                            </Link>
                          </li>

                          <li
                            className="main-nav-item"
                            style={{
                              color: "grey",
                              textDecoration: "none",
                            }}
                          >
                            Services
                            <li
                              className="sub-nav-item"
                              style={{
                                color: "grey",
                                textDecoration: "none",
                              }}
                            >
                              <Link
                                style={{
                                  color: "grey",
                                  textDecoration: "none",
                                }}
                                to="/online-services"
                              >
                                Online Services
                              </Link>
                              <Link
                                to="/sermon-series"
                                style={{
                                  color: "grey",
                                  textDecoration: "none",
                                  display: "block",
                                }}
                              >
                                Sermon Series
                              </Link>
                            </li>
                          </li>

                          <li>
                            <Link
                              to="/contact"
                              style={{
                                color: "grey",
                                textDecoration: "none",
                              }}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://methodistpreschools.org/bedok"
                        style={{ color: "white", textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Kindergarden
                      </Link>
                    </li>
                    {/* <li>
                      <input
                        className="search-bar"
                        placeholder="Search"
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                          borderRadius: 5,
                          border: "1px solid white",
                          textIndent: 5,
                          outline: "none",
                        }}
                      ></input>
                    </li> */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          {/* Mobile Nav */}
          <div
            className="mobile-nav"
            style={{
              width: "100%",
              padding: 15,
            }}
          >
            <img
              src="/logoWhite.png"
              alt="logo"
              style={{ width: "170px", height: "80px", objectFit: "contain" }}
            />
            <div
              className="header flex-row items-center flex  gap-30"
              style={{
                padding: 10,
                backgroundColor: "#0094b3",
                color: "#EEEEEE",
                justifyContent: "flex-end",
              }}
            >
              <div className="flex-row flex gap-30">
                <nav
                  className=""
                  style={{
                    color: "white",
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <div
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <svg
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      className="mobile-nav-menu"
                    >
                      <path
                        d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm4.998 13.245c0-.414-.336-.75-.75-.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75zm0-3.248c0-.414-.336-.75-.75-.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75zm0-3.252c0-.414-.336-.75-.75-.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75z"
                        fill-rule="nonzero"
                      />
                    </svg>
                  </div>
                  <li
                    className="mobile-nav-button"
                    style={{
                      listStyle: "none",
                      borderRadius: 99,
                      paddingBlock: 10,
                      paddingInline: 20,
                      width: "fit-content",
                      fontSize: 14,
                    }}
                  >
                    <Link
                      to="https://methodistpreschools.org/bedok"
                      style={{ color: "white", textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Kindergarden
                    </Link>
                  </li>
                </nav>
              </div>
            </div>
          </div>

          <ul
            className="flex-row flex gap-30 mobile-nav"
            style={{
              zIndex: 10,
              listStyle: "none",
              marginBottom: isOpen ? 10 : 0,
              flexDirection: "column",
              alignItems: "center",
              visibility: isOpen ? "visible" : "hidden",
              maxHeight: isOpen ? "500px" : 0,
              opacity: isOpen ? 1 : 0,
              transition: "all 500ms ease-in-out",
              fontSize: 20,
            }}
          >
            <li>
              <Link
                className="mobile-nav-item"
                to=".."
                style={{ textDecoration: "none" }}
              >
                Home
              </Link>
            </li>

            <li
              className="nav-tooltip"
              onClick={() => {
                setIsAboutOpen((prev) => !prev);
                setIsConnectOpen(false);
                setIsVisitOpen(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <div>
                <p className="mobile-nav-item">
                  About Us
                  <img
                    style={{
                      display: "inline",
                      verticalAlign: "middle",
                      rotate: isAboutOpen ? "180deg" : "0deg",
                      transition: "all 300ms linear",
                    }}
                    src="/caret.svg"
                    alt="caret dropdown"
                    width={20}
                  />
                </p>
              </div>
            </li>
            <ul
              style={{
                listStyle: "none",
                visibility: isAboutOpen ? "visible" : "hidden",
                opacity: isAboutOpen ? 1 : 0,
                height: isAboutOpen ? "30px" : 0,
                transition: "all 300ms",
                textAlign: "center",
                marginBottom: 10,
                marginTop: -25,
              }}
            >
              <li>
                <Link
                  className="mobile-nav-item"
                  to="/about"
                  style={{
                    textDecoration: "none",

                    opacity: isAboutOpen ? 1 : 0,
                    fontSize: isAboutOpen ? "1rem" : "0.5rem",
                    transition: "all 300ms",
                    transitionDelay: "100ms",
                  }}
                >
                  Who We Are
                </Link>
              </li>
              <li>
                <Link
                  className="mobile-nav-item"
                  to="/our-pastors-staff"
                  style={{
                    textDecoration: "none",

                    opacity: isAboutOpen ? 1 : 0,
                    fontSize: isAboutOpen ? "1rem" : "0.5rem",
                    transition: "all 300ms",
                    transitionDelay: "200ms",
                  }}
                >
                  Our Pastors & Staff
                </Link>
              </li>
            </ul>

            <li
              className="nav-tooltip"
              onClick={() => {
                setIsConnectOpen((prev) => !prev);
                setIsAboutOpen(false);
                setIsVisitOpen(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <div>
                <p className="mobile-nav-item">
                  Connect
                  <img
                    style={{
                      display: "inline",
                      verticalAlign: "middle",
                      rotate: isConnectOpen ? "180deg" : "0deg",
                      transition: "all 300ms linear",
                    }}
                    src="/caret.svg"
                    alt="caret dropdown"
                    width={20}
                  />
                </p>
              </div>
            </li>
            <ul
              style={{
                listStyle: "none",
                visibility: isConnectOpen ? "visible" : "hidden",
                opacity: isConnectOpen ? 1 : 0,
                height: isConnectOpen ? "60px" : 0,
                transition: "all 300ms",
                textAlign: "center",
                marginBottom: 10,
                marginTop: -25,
              }}
            >
              <li>
                <Link
                  className="mobile-nav-item"
                  to="/ministries"
                  style={{
                    textDecoration: "none",

                    opacity: isConnectOpen ? 1 : 0,
                    fontSize: isConnectOpen ? "1rem" : "0.5rem",
                    transition: "all 300ms",
                    transitionDelay: "100ms",
                  }}
                >
                  All Ministries
                </Link>
              </li>
              <li>
                <Link
                  className="mobile-nav-item"
                  to="/"
                  style={{
                    textDecoration: "none",

                    opacity: isConnectOpen ? 1 : 0,
                    fontSize: isConnectOpen ? "1rem" : "0.5rem",
                    transition: "all 300ms",
                    transitionDelay: "200ms",
                  }}
                >
                  COSC
                </Link>
              </li>
              <li>
                <Link
                  className="mobile-nav-item"
                  to="/"
                  style={{
                    textDecoration: "none",

                    opacity: isConnectOpen ? 1 : 0,
                    fontSize: isConnectOpen ? "1rem" : "0.5rem",
                    transition: "all 300ms",
                    transitionDelay: "300ms",
                  }}
                >
                  Disciple Group
                </Link>
              </li>
            </ul>

            <li style={{ marginBottom: 10 }}>
              <Link
                className="mobile-nav-item"
                to="/missions-release"
                style={{ textDecoration: "none" }}
              >
                Mission Release
              </Link>
            </li>

            <li
              className="nav-tooltip"
              onClick={() => {
                setIsVisitOpen((prev) => !prev);
                setIsConnectOpen(false);
                setIsAboutOpen(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <div>
                <p className="mobile-nav-item">
                  Visit Us
                  <img
                    style={{
                      display: "inline",
                      verticalAlign: "middle",
                      rotate: isVisitOpen ? "180deg" : "0deg",
                      transition: "all 300ms linear",
                    }}
                    src="/caret.svg"
                    alt="caret dropdown"
                    width={20}
                  />
                </p>
              </div>
            </li>
            <ul
              style={{
                listStyle: "none",
                visibility: isVisitOpen ? "visible" : "hidden",
                opacity: isVisitOpen ? 1 : 0,
                height: isVisitOpen ? "110px" : 0,
                transition: "all 300ms",
                textAlign: "center",
                marginBottom: 10,
                marginTop: -25,
              }}
            >
              <li>
                <Link
                  className="mobile-nav-item"
                  to="/getting-here"
                  style={{
                    textDecoration: "none",

                    opacity: isVisitOpen ? 1 : 0,
                    fontSize: isVisitOpen ? "1rem" : "0.5rem",
                    transitionDelay: "100ms",
                  }}
                >
                  Getting to BMC
                </Link>
              </li>
              <li>
                <Link
                  className="mobile-nav-item"
                  to="/online-services"
                  style={{
                    textDecoration: "none",

                    opacity: isVisitOpen ? 1 : 0,
                    fontSize: isVisitOpen ? "1rem" : "0.5rem",
                    transition: "all 300ms",
                    transitionDelay: "200ms",
                  }}
                >
                  Online Services
                </Link>
              </li>
              <li>
                <Link
                  className="mobile-nav-item"
                  to="/sermon-series"
                  style={{
                    textDecoration: "none",
                    fontSize: isVisitOpen ? "1rem" : "0.5rem",
                    opacity: isVisitOpen ? 1 : 0,
                    transition: "all 300ms",
                    transitionDelay: "300ms",
                  }}
                >
                  Sermon Series
                </Link>
              </li>
              <li>
                <Link
                  className="mobile-nav-item"
                  to="/contact"
                  style={{
                    textDecoration: "none",
                    opacity: isVisitOpen ? 1 : 0,
                    fontSize: isVisitOpen ? "1rem" : "0.5rem",
                    transition: "all 300ms",
                    transitionDelay: "400ms",
                  }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </>
  );
}
