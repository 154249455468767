import React, { useEffect, useState } from 'react'
import config from '../../app-config'
import notify from '../../utils/IANotification'
import { tidyDate } from '../../utils/helper'
import { MdCheck, MdQrCode } from 'react-icons/md'
import { FaCross } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import ModalLayout from '../../layouts/ModalLayout'
import QRCode from "react-qr-code";

export default function EventCheckInDetail() {
  const [sessionData, setSessionData] = useState({
    start_datetime: "",
    end_datetime: "",
    remarks: "",
    name: "",
  })
  const [participantsCheckIn, setParticipantsCheckIn] = useState([])
  const [showQRCode, setShowQRCode] = useState(false)
  useEffect(() => {
    fetchSessionDataById()
    fetchCheckInParticipants()
  }, [])

  let session_id = new URLSearchParams(window.location.search).get('session_id')
  let event_id = new URLSearchParams(window.location.search).get('event_id')

  const fetchSessionDataById = async () => {
    if (!session_id || session_id === 'new') return
    let endpoint = `${config.endpoint}/events/checkin/session/id/${session_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setSessionData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const createNewSession = async () => {
    let endpoint = `${config.endpoint}/events/checkin/session/`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        event_id,
        ...sessionData
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      window.location.href = `/admin/events/details?state=check-in_detail&event_id=${event_id}&session_id=${data.data.id}`
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateSessionData = async () => {
    let endpoint = `${config.endpoint}/events/checkin/session/id/${session_id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(sessionData)
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteSession = async () => {
    let endpoint = `${config.endpoint}/events/checkin/session/id/${session_id}`
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const fetchCheckInParticipants = async () => {
    if (!session_id || session_id === 'new') return
    let endpoint = `${config.endpoint}/events/checkin/participants/session/id/${session_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setParticipantsCheckIn(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  return (
    <>
      <ModalLayout modalTitle={`${sessionData?.name} Check-in`} isVisible={showQRCode} setIsVisible={setShowQRCode}>
        <h1 className="font-black text-xl">Event Check In</h1>
        <div className="flex flex-col center items-center">
          <QRCode
            size={200}
            value={JSON.stringify({
              session_id,
              event_id,
            })}
            className='m-4'
          />
          <h1 className="text-sm text-gray-400">{sessionData?.name}</h1>
        </div>
      </ModalLayout>
      <div>
        <div className="flex flex-row items-start gap-2">
          <div className="w-full">
            <div className="card">
              <div className="flex flex-row items-center gap-2 justify-between">
                <label htmlFor="" className="card-title">Session Information</label>
                <button className="flex flex-row items-center gap-2"
                  onClick={() => setShowQRCode(!showQRCode)}
                >
                  <MdQrCode className='text-xl' />
                  <label htmlFor="" className="text-xs">QR Code</label>
                </button>
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Session</label>
                <input type="text" className="text-input" value={sessionData.name} placeholder='e.g. Day Session 1'
                  onChange={(e) => {
                    setSessionData({ ...sessionData, name: e.target.value })
                  }}
                />
              </div>
              <div className="flex-row flex items-center gap-2 w-full">
                <div className="input-container">
                  <label htmlFor="" className="input-label">Valid From</label>
                  <input type="datetime-local" className="text-input" value={sessionData.start_datetime}
                    onChange={(e) => {
                      setSessionData({ ...sessionData, start_datetime: e.target.value })
                    }}
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="" className="input-label">Valid Till</label>
                  <input type="datetime-local" className="text-input" value={sessionData.end_datetime}
                    onChange={(e) => {
                      setSessionData({ ...sessionData, end_datetime: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Remarks</label>
                <textarea className="textarea-input" value={sessionData.remarks}
                  onChange={(e) => {
                    setSessionData({ ...sessionData, remarks: e.target.value })
                  }}
                />
              </div>
              <div className="btn-grp ">
                <div className='flex-row flex items-center gap-2'>
                  {session_id !== 'new' && <button className="button-cta" onClick={deleteSession}>Delete</button>}
                  <button className="button"
                    onClick={() => {
                      if (session_id === 'new') {
                        createNewSession()
                      } else {
                        updateSessionData()
                      }
                    }}
                  >Save</button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-8/12">
            <div className="card">
              <div className="flex flex-row items-center gap-2 justify-between">
                <label htmlFor="" className="card-title">Attendance</label>
                <label htmlFor="" className="text-xs text-gray-500">{participantsCheckIn?.filter(p => p?.checkin_data?.checkin_datetime).length} / {participantsCheckIn.length} has checked in</label>
              </div>

              {/* Participants */}
              {participantsCheckIn.length > 0 && (
                participantsCheckIn.map((participant, index) => {
                  return (
                    <div className="flex flex-row items-center justify-between w-full">
                      <label htmlFor="" className="input-label">{index + 1}. {participant?.user_data?.first_name} {participant?.user_data?.last_name}</label>
                      {/* <label htmlFor="" className="input-label">{participant?.checkin_data?.checkin_datetime? tidyDate(participant?.checkin_data?.checkin_datetime): "x"}</label> */}
                      <div
                        title={participant?.checkin_data?.checkin_datetime ? `Checked in at ${tidyDate(participant?.checkin_data?.checkin_datetime)}` : "Not Checked In"}
                      >
                        {participant?.checkin_data?.checkin_datetime
                          ? <MdCheck className='fill-green-500 text-xl' />
                          : <IoClose className='fill-red-500 text-xl' />
                        }
                      </div>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
