import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import { MdAdd, MdMusicNote, MdPersonAdd } from 'react-icons/md';
import { tidyDate, tidyDateRaw } from '../../utils/helper';
import { FaGlobe, FaGlobeAsia } from 'react-icons/fa';

export default function MissionsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [missionsData, setMissionsData] = useState([])
  const [loading, setLoading] = useState(true)
  const [showMemberModal, setShowMemberModal] = useState(false)
  const [tempUserData, setTempUserData] = useState({})
  const [query, setQuery] = useState("")

  useEffect(() => {
    fetchMissions()
  }, [])

  const fetchMissions = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/missions/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setMissionsData(data.data)
    }
    setLoading(false)
  }



  return (
    <AdminLayout
      navSelected={"Missions"}
      toolbar={
        <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Missions</h1>
          <button
            className="button flex-row items-center flex gap-2"
            onClick={() => {
              navigate("/admin/missions/details?mission_id=new")
            }}
          >
            <MdAdd size={15} />
            Mission
          </button>
        </div>
      }
    >
      <div className="column items-center justify-between w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>
      {loading ? (
        <div className="flex-row items-center flex justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {missionsData.map((missions, index) => {
            if (!JSON.stringify(missions).toLowerCase().includes(query.toLowerCase())) return null
            return (
              <div
                key={index}
                className="flex-row items-center flex justify-between w-100 card mt-10"
              >
                <div className="">
                  <h3>{missions.title}</h3>
                  <h3 className='card-subtitle'>{missions?.start_datetime ? tidyDate(missions.start_datetime) : "TBA"} - {missions?.end_datetime ? tidyDate(missions.end_datetime) : "TBA"}</h3>
                </div>
                <button
                  className="button"
                  onClick={() => {
                    navigate("/admin/missions/details?mission_id=" + missions.id)
                  }}
                >
                  View
                </button>
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}
