import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  useFetchAuthorisation,
  authorisationConverter,
  useFetchUserData,
} from "../../utils/AppFunctions";
import config from "../../app-config";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "../../layouts/AdminLayout";
import { MdAdd, MdDelete } from "react-icons/md";
import FileUploadField from "../../components/FileUploadField";
import notify from "../../utils/IANotification";

export default function AudioFormPage() {
  const navigate = useNavigate();
  const { isAuthorised, userRole } = useFetchAuthorisation("user");
  let user_data = useFetchUserData();

  let audio_id = new URLSearchParams(window.location.search).get("audio_id");

  const [audioData, setAudioData] = useState({
    title: "",
    remarks: "",
    metadata: [], // {key, value, label}
    is_published: 0,
    audio_category_id: "",
  })


  useEffect(() => {
    fetchAudioById();
  }, []); 

  const fetchAudioById = async () => {
    if (audio_id === "new") return;
    let endpoint = config.endpoint + "/audio/id/" + audio_id;
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    let data = await response.json();
    if (data.status === 200) {
      setAudioData(data.data);
    }
  }

  const createAudio = async () => {
    let endpoint = config.endpoint + "/audio/";
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(audioData),
    });
    let data = await response.json();
    if (data.status === 200) {
      navigate(`/admin/audio/details?audio_id=${data.data.id}`);
      notify({ message: "Audio has been created successfully", type: "success" });
    }else{
      notify({ message: data.message, type: "error" });
    }
  }

  const saveAudio = async () => {
    let endpoint = config.endpoint + "/audio/id/" + audio_id;
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(audioData),
    });
    let data = await response.json();
    if (data.status === 200) {
      notify({ message: "Audio has been saved successfully", type: "success" });
      fetchAudioById();
    } else {
      notify({ message: data.message, type: "error" });
    }
  }

  const deleteAudio = async () => {
    let endpoint = config.endpoint + "/audio/id/" + audio_id;
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    let data = await response.json();
    if (data.status === 200) {
      navigate("/admin/audio");
      notify({ message: "Audio has been deleted successfully", type: "success" });
    } else {
      notify({ message: data.message, type: "error" });
    }
  }






  return (
    <AdminLayout navSelected={"Audio & Worship"}>
      <div className="flex flex-row items-start gap-2">
        <div className="w-full">
          <div className="card w-full">
            <p className="card-title">Audio Information</p>
            {/* basic information */}
            <div className="input-container">
              <label className="input-label">Title</label>
              <input
                type="text"
                className="text-input"
                value={audioData.title}
                onChange={(e) => {
                  setAudioData({ ...audioData, title: e.target.value });
                }}
              />
            </div>
            <div className="input-container">
              <label className="input-label">Remarks</label>
              <textarea
                className="textarea-input"
                value={audioData.remarks}
                onChange={(e) => {
                  setAudioData({ ...audioData, remarks: e.target.value });
                }}
              />
            </div>
          </div>

          <div className="card mt-2">
            <div className="flex flex-row items-center justify-between w-full">
              <p className="card-title">Metadata</p>
              <button className="text-xs flex flex-row gap-2 items-center"
                onClick={() => {
                  let newMetadata = audioData.metadata;
                  newMetadata.push({ key: "", value: "", label: "" });
                  setAudioData({ ...audioData, metadata: newMetadata });
                }}
              >
                <MdAdd size={15} />
                Add Metadata
              </button>
            </div>

            {audioData?.metadata?.length > 0 
            ? audioData.metadata.map((item, index) => {
              return (
                <div className="flex flex-row items-center gap-2 mt-2">
                  <div className="input-container">
                    <label className="input-label">Key</label>
                    <input
                      type="text"
                      className="text-input"
                      placeholder="e.g. artist"
                      value={item.key}
                      onChange={(e) => {
                        let newMetadata = [...audioData.metadata];
                        newMetadata[index].key = e.target.value?.toLowerCase();
                        setAudioData({ ...audioData, metadata: newMetadata });
                      }}
                    />
                  </div>
                  <div className="input-container">
                    <label className="input-label">Label</label>
                    <input
                      type="text"
                      className="text-input"
                      value={item.label}
                      placeholder="e.g. Artist"
                      onChange={(e) => {
                        let newMetadata = [...audioData.metadata];
                        newMetadata[index].label = e.target.value;
                        setAudioData({ ...audioData, metadata: newMetadata });
                      }}
                    />
                  </div>
                  <div className="input-container">
                    <label className="input-label">Value</label>
                    <input
                      type="text"
                      className="text-input"
                      placeholder="e.g. John Doe"
                      value={item.value}
                      onChange={(e) => {
                        let newMetadata = [...audioData.metadata];
                        newMetadata[index].value = e.target.value;
                        setAudioData({ ...audioData, metadata: newMetadata });
                      }}
                    />
                  </div>
                  <button className="flex flex-row items-center gap-2 whitespace-nowrap text-xs w-fit fit"
                    onClick={() => {
                      let newMetadata = [...audioData.metadata];
                      newMetadata.splice(index, 1);
                      setAudioData({ ...audioData, metadata: newMetadata });
                    }}
                  >
                    <MdDelete size={15} className="text-xs" />
                    Remove
                  </button>

                </div>
              )
            })
              : <p className="text-xs text-center text-gray-400 mt-2">No metadata added</p>
            }

          </div>
        </div>

        {audio_id !== "new" && <div className="card w-full">
          <p className="card-title">Audio Information</p>
          <FileUploadField
            max_file_size={1000000}
            max_files={1}
            item_index={0}
            upload_url={config.endpoint + "/file/audio/id/" + audio_id}
            text="Upload Audio File"
            subtext="Audio file can be a worship session, sermon recording, etc."
            onChange={(files) => {
              fetchAudioById();
            }}
          />
          {audioData?.file_data?.ext_file_path && <audio controls src={`${config.endpoint}${audioData?.file_data?.ext_file_path}`}></audio>}
          {/* <p>{`${config.endpoint}${audioData?.file_data?.ext_file_path}`}</p> */}
        </div>}
      </div>

      <div className="flex flex-row items-center justify-end mt-2 gap-2">
        {audio_id !== "new" && <button className="button-delete"
          onClick={() => {
            deleteAudio();
          }}
        >
          Delete
        </button>}
        <button className="button"
          onClick={() => {
            if (audio_id === "new") {
              createAudio();
            } else {
              saveAudio();
            }
          }}
        >
          Save
        </button>
      </div>
    </AdminLayout>
  );
}