import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import { MdAdd } from 'react-icons/md';
import { tidyDate } from '../../utils/helper';

export default function ResourcesPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [resources, setResources] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")

  useEffect(() => {
    fetchResources()
  }, [])

  const fetchResources = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/resources"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setResources(data.data)
    }
    setLoading(false)
  }



  return (
    <AdminLayout
      navSelected={"Resources"}
      toolbar={
        <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Resources</h1>
          <button
            className="button flex-row items-center flex gap-2"
            onClick={() => {
              navigate("/admin/resources/details?resource_id=new")
            }}
          >
            <MdAdd size={15} />
            Resource
          </button>
        </div>
      }
    >

      <div className="column items-center justify-between w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>

      {loading ? (
        <div className="flex-row items-center flex justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {resources.map((resource, index) => {
            if (!resource.name.toLowerCase().includes(query.toLowerCase())) return null
            return (
              <div
                key={index}
                className="flex-row items-center flex justify-between w-100 card mt-10"
              >
                <div className="">

                  <h3>{resource.name}</h3>
                  <h3 className='card-subtitle'>{tidyDate(resource.available_from)} - {tidyDate(resource.available_to)}</h3>
                </div>
                <button
                  className="button"
                  onClick={() => {
                    navigate("/admin/resources/details?resource_id=" + resource.id)
                  }}
                >
                  View
                </button>
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}
