import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import { MdAdd, MdCalendarToday } from 'react-icons/md';
import { tidyDate } from '../../utils/helper';

export default function EventPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [eventData, setEventData] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")

  useEffect(() => {
    fetchEvents()
  }, [])

  const fetchEvents = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/events/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setEventData(data.data)
    }
    setLoading(false)
  }

  return (
    <AdminLayout
      navSelected={"Events"}
      toolbar={
        <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Events</h1>
          <button
            className="button flex-row items-center flex gap-2"
            onClick={() => {
              navigate("/admin/events/details?event_id=new")
            }}
          >
            <MdAdd size={15} />
            Event
          </button>
        </div>
      }
    >



      <div className="column items-center justify-between w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>

      {
        loading ? (
          <div className="flex-row items-center flex justifyCenter w-100">
            <h1>Loading...</h1>
          </div>
        ) : (
          <div className="w-100">
            {eventData.map((event, index) => {
              if (!JSON.stringify(event).toLowerCase().includes(query.toLowerCase())) return null 
              return (
                <div
                  key={index}
                  className="flex-row items-center flex justify-between w-100 card mt-10"
                >
                  <div className="">
                    <h3>{event.name}</h3>
                    <h3 className='card-subtitle'>{tidyDate(event?.event_start_datetime)} • {event.venue}</h3>
                  </div>
                  <button
                    className="button"
                    onClick={() => {
                      navigate("/admin/events/details?event_id=" + event.id)
                    }}
                  >
                    View
                  </button>
                </div>
              )
            })}
          </div>
        )
      }
    </AdminLayout >
  )
}
