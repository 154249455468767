import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  useFetchAuthorisation,
  authorisationConverter,
  useFetchUserData,
} from "../../utils/AppFunctions";
import config from "../../app-config";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "../../layouts/AdminLayout";
import { capitaliseFirstLetter, tidyDate } from "../../utils/helper";
import notify from "../../utils/IANotification";
import Table from "../../components/app-components/Table";

export default function LibraryLoanRecordsPage() {
  const navigate = useNavigate();
  const { isAuthorised, userRole } = useFetchAuthorisation("user");
  let user_data = useFetchUserData();

  const [recordsData, setRecordsData] = useState([]);
  const [query, setQuery] = useState("")

  useEffect(() => {
    fetchLoanRecords();
  }, []);

  const fetchLoanRecords = async () => {
    let endpoint = `${config.endpoint}/library/records`;
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include"
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setRecordsData(data.data?.reverse())
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const returnItem = async (record_id) => {
    let endpoint = `${config.endpoint}/library/admin/return`
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ record_id, })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      fetchLoanRecords()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const renewItem = async (record_id) => {
    let endpoint = `${config.endpoint}/library/admin/renew`
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ record_id, })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      fetchLoanRecords()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  let table_columns = [
    {
      header: "Name",
      data: (row) => `${row?.user_data?.first_name} ${row?.user_data?.last_name}`
    },
    {
      header: "Email",
      data: (row) => row?.user_data?.email
    },
    {
      header: "Item",
      data: (row) => `${row?.library_item_data?.title}`
    },
    {
      header: "Status",
      data: (row) => row?.is_returned == 1 ? "Returned" : `On loan till ${tidyDate(row?.return_date)}`
    },

  ]

  return (
    <AdminLayout navSelected={"Borrowed Items"}
      toolbar={
        <>
          <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
            <h1 className='medium'>Loan Records</h1>
          </div>
        </>
      }
    >

      <div className="column items-center justify-between w-100 mb-4">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>

      <Table
        columns={table_columns}
        data={recordsData?.map((record) => {
          if (query && !JSON.stringify(record).toLowerCase().includes(query.toLowerCase())) return {}
          return record
        })?.filter((record) => Object.keys(record).length > 0)}
        actions={(row) => {
          return (
            <>
              <div className="flex flex-row items-center gap-2">
                {row?.is_returned == 0 && <button className="text-xs hover:underline" onClick={() => {
                  returnItem(row.id)
                }}>Return</button>}
                {row?.is_returned == 0 && <button className="text-xs hover:underline" onClick={() => {
                  renewItem(row.id)
                }}>Renew</button>}
                <button className="text-xs hover:underline" onClick={() => {
                  navigate("/admin/users/details?user_id=" + row.user_id, { replace: false })
                }}>View User</button>
              </div>
            </>
          )
        }}
      />
    </AdminLayout>
  )
}