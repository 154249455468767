import React from 'react'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import AdminLayout from '../../layouts/AdminLayout'
import { MdChurch, MdDocumentScanner, MdEditDocument, MdGroup, MdGroups2, MdGroups3, MdPerson3 } from 'react-icons/md'
import { useEffect } from 'react'
import { useState } from 'react'
import { capitaliseFirstLetter, currency } from '../../utils/helper'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, registerables } from "chart.js";
import { Doughnut, Bar, Line } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, ...registerables);


export default function AdminHomePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [userAnalyticData, setUserAnalyticData] = useState({})

  const datetime = () => {
    let dateObj = new Date()
    let hours = dateObj.getHours()
    let minutes = dateObj.getMinutes()
    let seconds = dateObj.getSeconds()
    let day = dateObj.getDay()
    let date = dateObj.getDate()
    let month = dateObj.getMonth()
    let year = dateObj.getFullYear()
    const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    return { date: `${date} ${MONTHS[month].slice(0, 3)}`, time: `${hours}:${minutes}:${seconds}`, day: DAYS[day], month, year }
  }

  const COLOR = "var(--primary)"
  const SIZE = 30


  useEffect(() => {
    fetchUserAnalytics()
  }, [])

  const fetchUserAnalytics = async () => {
    let endpoint = config.endpoint + "/analytics/data/users"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setUserAnalyticData(data.data)
    }
  }

  return (
    <AdminLayout navSelected={"Dashboard"}>
      <p style={{ fontWeight: "600", fontSize: 20 }}>Hello {user_data?.first_name},</p>
      <p style={{ marginTop: 10 }}>It's {datetime().day}, {datetime().date}</p>

      {Object.keys(userAnalyticData).length > 0 && <>
        <div className="flex-row items-center flex gap-2 mt-10">
          <div className="flex-row flex gap-4 items-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)", padding: 10, borderRadius: 5, minWidth: 200 }}>
            <MdGroup size={SIZE} color={COLOR} />
            <div>
              <p style={{ fontWeight: "600" }}>{currency(userAnalyticData?.total_users)}</p>
              <p style={{ marginTop: 0, paddingTop: 0, fontSize: 13 }}>Total Users</p>
            </div>
          </div>

          <div className="flex-row flex gap-4 items-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)", padding: 10, borderRadius: 5, minWidth: 200 }}>
            <MdGroups2 size={SIZE} color={COLOR} />
            <div>
              <p style={{ fontWeight: "600" }}>{currency(userAnalyticData?.members)}</p>
              <p style={{ marginTop: 0, paddingTop: 0, fontSize: 13 }}>Registered Users</p>
            </div>
          </div>

          <div className="flex-row flex gap-4 items-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)", padding: 10, borderRadius: 5, minWidth: 200 }}>
            <MdGroups3 size={SIZE} color={COLOR} />
            <div>
              <p style={{ fontWeight: "600" }}>{currency(userAnalyticData?.guest_users)}</p>
              <p style={{ marginTop: 0, paddingTop: 0, fontSize: 13 }}>Guest</p>
            </div>
          </div>
        </div>

        <p className="medium mt-4 mb-4">Analytics</p>

        <div className="flex-row flex gap-2">
          {/* Pie Chart - Devices */}
          <div className='card'>
            <p className="card-title">Device Sessions</p>
            <Doughnut
              title='User Analytics'
              options={{
              }}
              data={{
                labels: Object.keys(userAnalyticData?.users_by_device)?.map((device) => capitaliseFirstLetter(device)),
                datasets: [
                  {
                    data: Object.values(userAnalyticData?.users_by_device),
                    backgroundColor: ["#007bff", "#dc3545", "#ffc107", "#28a745", "#17a2b8", "#f8f9fa"],
                  },
                ],
              }}
            />
          </div>
          {/* Pie Chart - User */}
          <div className='card'>
            <p className="card-title">Users</p>
            <Doughnut
              title='User Analytics'
              options={{
              }}
              data={{
                labels: ["Members", "Guests"],
                datasets: [
                  {
                    data: [userAnalyticData?.members, userAnalyticData?.guest_users],
                    backgroundColor: ["#007bff", "#dc3545"],
                    hoverBackgroundColor: ["#0056b3", "#c82333"],
                  },
                ],
              }}
            />
          </div>


        </div>

        <div className="flex-row flex gap-2 mt-10">


          {/* Line Chart - Weekly Users */}
          <div className="card">
            <p className="card-title">Weekly Usage</p>
            <Line
              options={{}}
              data={{
                labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                datasets: [
                  {
                    label: "Weekly Users",
                    data: Object.values(userAnalyticData?.daily_users),
                    backgroundColor: "#007bff",
                  },
                ],

              }}
            />
          </div>

          {/* Bar Chart - Yearly Users */}
          <div className="card">
            <p className="card-title">Yearly Usage</p>
            <Bar
              options={{}}
              data={{
                labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                datasets: [
                  {
                    label: "Monthly Users",
                    data: Object.values(userAnalyticData?.monthly_users),
                    backgroundColor: "#007bff",
                  },
                ],

              }}
            />
          </div>
        </div>
      </>}
      {/* <pre>{JSON.stringify(userAnalyticData, null, 2)}</pre> */}
    </AdminLayout>
  )
}
