import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import { tidyDate } from '../../utils/helper';

export default function ContactPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [contacts, setContacts] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")

  useEffect(() => {
    fetchAllContact()
  }, [])

  const fetchAllContact = async () => {
    setLoading(true)
    let endpoint = `${config.endpoint}/contact/`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
    let data = await response.json()
    if (data.status === 200) {
      setContacts(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
    setLoading(false)
  }

  return (
    <AdminLayout
      navSelected={"Contact Form"}
      toolbar={
        <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Contact Form</h1>
        </div>
      }
    >
      <div className="column items-center justify-between w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>

      {loading ? (
        <div className="flex-row items-center flex justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {contacts.map((data, index) => {
            if (!JSON.stringify(data).toLowerCase().includes(query.toLowerCase())) return null
            return (
              <div
                key={index}
                className="flex-row items-center flex justify-between w-100 card mt-10 cursor-pointer"
                onClick={() => {
                  navigate("/admin/contact/details?form_id=" + data.id)
                }}
              >
                <div className="w-100">
                  <div className="flex-row flex justify-between items-center w-100">
                    <h3>{data?.name}</h3>
                    {/* <p style={{fontSize:12}}>{data?.type}</p> */}
                  </div>
                  <h3 className='gray card-subtitle'>{data?.email || data?.contact_number}</h3>
                  <p className='gray card-subtitle'>{tidyDate(data?.createdAt)}</p>
                </div>
                <button
                  className="button"
                  onClick={() => {
                    navigate("/admin/contact/details?form_id=" + data.id)
                  }}
                >
                  View
                </button>
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}
