import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import AdminEventsNavbar from '../../components/AdminEventsNavbar';
import Switch from "react-switch";
import Select from 'react-select'
import { MdAdd, MdCancel, MdDelete } from 'react-icons/md';
import EventsAccommodation from '../../components/events/EventsAccommodation';
import EventForms from '../../components/events/EventForms';
import FileUploadField from '../../components/FileUploadField';
import EventParticipant from '../../components/events/EventParticipant';
import EventParticipantDetail from '../../components/events/EventParticipantDetail';
import EventCheckIn from '../../components/events/EventCheckIn';
import EventCheckInDetail from '../../components/events/EventCheckInDetail';

export default function EventFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let event_id = new URLSearchParams(window.location.search).get("event_id")
  let selectedItem = new URLSearchParams(window.location.search).get("state") ? new URLSearchParams(window.location.search).get("state") : "general"

  const [eventData, setEventData] = useState({
    name: "",
    description: "",
    event_start_datetime: "",
    event_end_datetime: "",
    venue: "",
    price: "0",
    is_unlimited_capacity: "0",
    capacity: "100",
    has_accommodation: "0",
    has_transportation: "0",
    accommodation_id: "",
    transportation_id: "",
    registration_start_datetime: "",
    registration_end_datetime: "",
    is_published: "0",
    is_online: "0",
    link: "",
    transport_price: "0",
    allow_waitlist: "0",
    event_type: "regular",
  })

  const [accommodations, setAccommodations] = useState([]) //
  const [selectedAccommodation, setSelectedAccommodation] = useState(null)


  const fetchAccommodations = async () => {
    if (!event_id) return
    let endpoint = `${config.endpoint}/events/accommodations/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setAccommodations(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }



  const fetchEventById = async () => {
    if (!event_id || event_id === "new") return
    let endpoint = `${config.endpoint}/events/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      console.log(data.data)
      setEventData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    fetchEventById()
    fetchAccommodations()
  }, [])


  const createEvent = async () => {
    let endpoint = `${config.endpoint}/events/`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...eventData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/events/details?event_id=" + data.data.id)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateEvent = async () => {
    if (!event_id) return
    let endpoint = `${config.endpoint}/events/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...eventData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteEvent = async () => {
    if (!event_id) return
    let endpoint = `${config.endpoint}/events/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/events")
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const variantModel = {
    name: "Adults",
    min_age: 13,
    limit: 2
  }

  return (
    <AdminLayout
      navSelected={"Events"}
    // regularContainer={event_id !== "new"}
    >
      <AdminEventsNavbar selected={selectedItem} has_accommodation={eventData.has_accommodation} has_transportation={eventData.has_transportation} />

      {/* GENERAL */}
      {selectedItem === "general" && <div className="flex-row flex gap-2 alignStart">
        <div className="w-100">
          <div className="card">
            <p className="card-title">Event Details</p>
            {event_id !== "new" &&
              <FileUploadField
                upload_url={`${config.endpoint}/file/event/cover/id/${event_id}`}
                max_files={1}
                text={"Upload Event Image"}
                subtext={"Drag and drop an image here or click to upload"}
                onChange={(files) => {

                }}
              />
            }
            <div className="flex-row flex gap-2 items-center">
              <div className="input-container">
                <label htmlFor="name" className='input-label'>Name</label>
                <input type="text" id="name" className='text-input' value={eventData.name}
                  onChange={(e) => {
                    setEventData({ ...eventData, name: e.target.value })
                  }}
                />
              </div>
              <div className="input-container">
                <label htmlFor="name" className='input-label'>Type</label>
                <select name="" id="" className="select-input"
                  value={eventData.event_type}
                  onChange={(e) => {
                    let payload = { ...eventData, event_type: e.target.value }
                    if (e.target.value === "online") {
                      payload.is_online = "1"
                      payload.has_accommodation = "0"
                      payload.has_transportation = "0"
                    }

                    if (e.target.value === "camp") {
                      payload.has_accommodation = "1"
                      payload.has_transportation = "1"
                    }
                    setEventData(payload)
                  }}
                >
                  <option value="regular">Regular</option>
                  <option value="camp">Camp</option>
                  <option value="volunteer">Volunteer - ServOps</option>
                  <option value="workshop">Workshop</option>
                  <option value="training">Training</option>
                  <option value="conference">Conference</option>
                  <option value="seminar">Seminar</option>
                  <option value="webinar">Webinar</option>
                  <option value="online">Online</option>
                </select>
              </div>
              <div className="flex-row flex gap-2 items-center">
                <label htmlFor="is_published" className='input-label'>Published</label>
                <Switch id="is_published" checked={eventData.is_published == "1"} onChange={(checked) => {
                  setEventData({ ...eventData, is_published: checked ? "1" : "0" })
                }} />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="description" className='input-label'>Description</label>
              <textarea id="description" className='textarea-input' value={eventData.description}
                onChange={(e) => {
                  setEventData({ ...eventData, description: e.target.value })
                }}
              />
            </div>
            <div className="flex-row flex gap-2 items-center">
              <div className="input-container">
                <label htmlFor="event_start_datetime" className='input-label'>From</label>
                <input type="datetime-local" id="event_start_datetime" className='text-input' value={eventData.event_start_datetime}
                  onChange={(e) => {
                    setEventData({ ...eventData, event_start_datetime: e.target.value })
                  }}
                />
              </div>
              <div className="input-container">
                <label htmlFor="event_end_datetime" className='input-label'>To</label>
                <input type="datetime-local" id="event_end_datetime" className='text-input' value={eventData.event_end_datetime}
                  onChange={(e) => {
                    setEventData({ ...eventData, event_end_datetime: e.target.value })
                  }}
                />
              </div>
            </div>

            <div className="input-container">
              <label htmlFor="venue" className='input-label'>Venue</label>
              <input type="text" id="venue" className='text-input' value={eventData.venue}
                onChange={(e) => {
                  setEventData({ ...eventData, venue: e.target.value })
                }}
              />
            </div>

            <div className="flex-row flex gap-2 items-center">
              <div className="input-container">
                <label htmlFor="price" className='input-label'>Price</label>
                <input type="number" id="price" className='text-input' value={eventData.price}
                  onChange={(e) => {
                    setEventData({ ...eventData, price: e.target.value })
                  }}
                />
              </div>
              {eventData?.is_unlimited_capacity == "0" && <div className="input-container">
                <label htmlFor="capacity" className='input-label'>Capacity</label>
                <input type="number" id="capacity" className='text-input' value={eventData.capacity}
                  onChange={(e) => {
                    setEventData({ ...eventData, capacity: e.target.value })
                  }}
                />
              </div>}
            </div>

            {eventData.is_registration_required == "1" && <>
              <p className="card-title">Registration</p>
              <div className="flex-row flex gap-2 items-center">
                <div className="input-container">
                  <label htmlFor="registration_start_datetime" className='input-label'>From</label>
                  <input type="datetime-local" id="registration_start_datetime" className='text-input' value={eventData.registration_start_datetime}
                    onChange={(e) => {
                      setEventData({ ...eventData, registration_start_datetime: e.target.value })
                    }}
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="registration_end_datetime" className='input-label'>To</label>
                  <input type="datetime-local" id="registration_end_datetime" className='text-input' value={eventData.registration_end_datetime}
                    onChange={(e) => {
                      setEventData({ ...eventData, registration_end_datetime: e.target.value })
                    }}
                  />
                </div>
              </div>
            </>}

            {eventData.has_transportation == "1" && <>
              <p className="card-title">Transportation</p>
              <div className="input-container">
                <label htmlFor="transport_price" className='input-label'>Transport Price</label>
                <input type="number" id="transport_price" className='text-input' value={eventData.transport_price}
                  onChange={(e) => {
                    setEventData({ ...eventData, transport_price: e.target.value })
                  }}
                />
              </div>
            </>}

            {eventData.is_online == "1" && <>
              <p className="card-title">Online Event</p>
              <div className="input-container">
                <label htmlFor="link" className='input-label'>Link</label>
                <input type="text" id="link" className='text-input' value={eventData.link} placeholder='https://us.zoom.com'
                  onChange={(e) => {
                    setEventData({ ...eventData, link: e.target.value })
                  }}
                />
              </div>
            </>}


            <div className="column alignEnd">
              <div className="flex-row flex gap-2 items-center">
                {event_id !== "new" && <button className="button-delete"
                  onClick={() => {
                    deleteEvent()
                  }}
                >Delete</button>}
                <button className="button"
                  onClick={() => {
                    if (event_id === "new") {
                      createEvent()
                    } else {
                      updateEvent()
                    }
                  }}
                >Save</button>
              </div>
            </div>
          </div>
        </div>
        <div className='w-100'>
          <div className="card">
            <p className="card-title">Configurations</p>
            <div className="flex-row flex gap-2 items-center justify-between mb-2">
              <label htmlFor="is_unlimited_capacity" className='input-label'>Unlimited Capacity</label>
              <Switch id="is_unlimited_capacity" checked={eventData.is_unlimited_capacity == "1"} onChange={(checked) => {
                setEventData({ ...eventData, is_unlimited_capacity: checked ? "1" : "0" })
              }} />
            </div>
            <div className="flex-row flex gap-2 items-center justify-between mb-2">
              <label htmlFor="is_unlimited_capacity" className='input-label'>Online Event</label>
              <Switch id="is_unlimited_capacity" checked={eventData.is_online == "1"} onChange={(checked) => {
                setEventData({ ...eventData, is_online: checked ? "1" : "0", has_accommodation: checked && "0", has_transportation: checked && "0" })
              }} />
            </div>
            <div className="flex-row flex gap-2 items-center justify-between mb-2">
              <label htmlFor="has_accommodation" className='input-label'>Requires Registration</label>
              <Switch id="has_accommodation" checked={eventData.is_registration_required == "1"} onChange={(checked) => {
                if (!checked) {
                  setEventData({ ...eventData, is_registration_required: "0", has_accommodation: "0", has_transportation: "0" })
                  return
                }
                setEventData({ ...eventData, is_registration_required: checked ? "1" : "0" })
              }} />
            </div>
            <div className="flex-row flex gap-2 items-center justify-between mb-2">
              <label htmlFor="has_accommodation" className='input-label'>Accommodation</label>
              <Switch id="has_accommodation" checked={eventData.has_accommodation == "1"} onChange={(checked) => {
                if (checked) {
                  setEventData({ ...eventData, has_accommodation: checked ? "1" : "0", is_registration_required: "1", is_online: "0" })
                  return
                }
                setEventData({ ...eventData, has_accommodation: checked ? "1" : "0", })

              }} />
            </div>
            <div className="flex-row flex gap-2 items-center justify-between mb-2">
              <label htmlFor="has_transportation" className='input-label'>Transportation</label>
              <Switch id="has_transportation" checked={eventData.has_transportation == "1"} onChange={(checked) => {
                if (checked) {
                  setEventData({ ...eventData, has_transportation: checked ? "1" : "0", is_registration_required: "1", is_online: "0" })
                  return
                }
                setEventData({ ...eventData, has_transportation: checked ? "1" : "0", })
              }} />
            </div>
            <div className="flex-row flex gap-2 items-center justify-between mb-2">
              <label htmlFor="allow_waitlist" className='input-label'>Waitlist</label>
              <Switch id="allow_waitlist" checked={eventData.allow_waitlist == "1"} onChange={(checked) => {
                if (checked) {
                  setEventData({ ...eventData, allow_waitlist: checked ? "1" : "0", })
                  return
                }
                setEventData({ ...eventData, allow_waitlist: checked ? "1" : "0", })
              }} />
            </div>
          </div>
        </div>
      </div>}

      {/* ACCOMMODATION */}
      {selectedItem === "accommodation" && <EventsAccommodation />}

      {/* Forms */}
      {selectedItem === "forms" && <EventForms />}

      {/* PARTICIPANTS */}
      {selectedItem === "participants" && <EventParticipant />}
      {selectedItem === "participants_detail" && <EventParticipantDetail />}
      {selectedItem === "waitlist" && <EventParticipant />}

      {/* CHECKIN */}
      {selectedItem === "check-ins" && <EventCheckIn />}
      {selectedItem === "check-in_detail" && <EventCheckInDetail />}
    </AdminLayout>
  )
}
