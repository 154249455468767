import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  useFetchAuthorisation,
  authorisationConverter,
  useFetchUserData,
} from "../../utils/AppFunctions";
import config from "../../app-config";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "../../layouts/AdminLayout";
import { MdOutlineUpload, MdUpload } from "react-icons/md";
import ModalLayout from "../../layouts/ModalLayout";
import FileUploadField from "../../components/FileUploadField";

export default function GalleryPage() {
  const navigate = useNavigate();
  const { isAuthorised, userRole } = useFetchAuthorisation("user");
  let user_data = useFetchUserData();
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [galleryData, setGalleryData] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")
  const [selectedImages, setSelectedImages] = useState([])


  useEffect(() => {
    fetchGalleryData()
  }, [])

  const fetchGalleryData = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/photos/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setGalleryData(data.data)
    }
    setLoading(false)
  }

  return (
    <>
      <ModalLayout modalTitle={"Upload Photo"} isVisible={showUploadModal} setIsVisible={setShowUploadModal}>
        <FileUploadField
          text={"Upload Media"}
          upload_url={config.endpoint + "/photos/"}
          subtext={"Upload photos to the gallery"}
          onChange={() => {
            setShowUploadModal(false)
            fetchGalleryData()
          }}
        />
      </ModalLayout>

      <AdminLayout navSelected={"Photo Gallery"}
        toolbar={
          <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
            <h1 className='medium'>Photo Gallery</h1>
            <button
              className="button flex-row items-center flex gap-2"
              onClick={() => {
                setShowUploadModal(true)
              }}
            >
              <MdUpload size={15} />
              Upload
            </button>
          </div>
        }
      >
        <div className="flex flex-row items-start gap-2">
          <div className="w-full">
            <div className="column items-center justify-between w-100 mb-40">
              <input
                type="text"
                placeholder="Search..."
                className='text-input w-100'
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value)
                }}
              />
            </div>
            {loading ? (
              <div className="flex-row items-center flex justifyCenter w-100">
                <h1>Loading...</h1>
              </div>
            ) : (
              <div className="w-100 flex-row items-center flex gap-2 flex-wrap">
                {galleryData.map((data, index) => {
                  if (!JSON.stringify(data).toLowerCase().includes(query.toLowerCase())) return null
                  return (
                    <div
                      key={index}
                      className={`${selectedImages?.includes(data?.id) ? "border-2 border-gray-500" : ""} cursor-pointer`}
                      onClick={(e)=>{
                        if (e.shiftKey) {
                          let lastSelectedIndex = galleryData.findIndex((item) => item?.id === selectedImages[selectedImages.length - 1])
                          let currentIndex = galleryData.findIndex((item) => item?.id === data?.id)
                          let newSelectedImages = []
                          if (lastSelectedIndex < currentIndex) {
                            newSelectedImages = galleryData.slice(lastSelectedIndex, currentIndex + 1).map((item) => item?.id)
                          } else {
                            newSelectedImages = galleryData.slice(currentIndex, lastSelectedIndex + 1).map((item) => item?.id)
                          }
                          setSelectedImages([...newSelectedImages])
                          return
                        }

                        if(selectedImages.includes(data?.id)){
                          let newSelectedImages = selectedImages
                          newSelectedImages.splice(selectedImages.indexOf(data?.id), 1)
                          setSelectedImages([...newSelectedImages])
                        } else {
                          setSelectedImages([...selectedImages, data?.id])
                        }
                      }}
                    >
                      <img src={`${config.endpoint}${data?.file_data?.ext_file_path}`} style={{ width: 100, height: 100 }} loading="lazy" />
                    </div>
                  )
                })}
              </div>
            )}
          </div>

          {selectedImages.length > 0 && <div className="w-5/12 card min-w-0">
              <p className="card-title">{selectedImages.length} selected</p>
          </div>}
        </div>
      </AdminLayout>
    </>
  )
}