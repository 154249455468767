import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import Select from 'react-select'
import Switch from 'react-switch'
import { DAYS } from '../../utils/helper';
import { MdPersonAdd } from 'react-icons/md';
import ModalLayout from '../../layouts/ModalLayout';
import FileUploadField from '../../components/FileUploadField';

export default function DiscipleGroupFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let dg_id = new URLSearchParams(window.location.search).get("dg_id")

  useEffect(() => {
    fetchDGMembers()
    fetchDGLeaders()
    fetchPlatformUsers()
    fetchDGClusterData()

    fetchDGData()
  }, [])

  const LANGUAGE = [
    { label: "English", value: "en" },
    { label: "Chinese", value: "ch" },
    { label: "Tamil", value: "ta" },
    { label: "Malay", value: "ml" },
  ]

  const [dgClusterData, setDGClusterData] = useState([])
  const [showMemberModal, setShowMemberModal] = useState(false)
  const [tempUserData, setTempUserData] = useState({})
  const [temptags, setTemptags] = useState("")
  const [dgData, setDGData] = useState({
    name: "",
    description: "",
    day: "",
    datetime: "",
    is_accepting_members: 1,
    cluster_id: "",
    tagline: "",
    tags: [],
    language: "en",
  })
  const [dgMembers, setDgMembers] = useState([])
  const [dgLeaders, setDgLeaders] = useState([])

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchDGData = async () => {
    if (!dg_id || dg_id === "new") return
    let endpoint = config.endpoint + "/dg/id/" + dg_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setDGData(data.data)
    }
  }

  const fetchDGMembers = async () => {
    if (!dg_id || dg_id === "new") return
    let endpoint = config.endpoint + "/dg/members/" + dg_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setDgMembers(data.data)
    }
  }

  const fetchDGClusterData = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/dg/clusters/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setDGClusterData(data.data)
    }
    setLoading(false)
  }

  const fetchDGLeaders = async () => {
    if (!dg_id || dg_id === "new") return
    let endpoint = config.endpoint + "/dg/leaders/" + dg_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setDgLeaders(data.data)
    }
  }

  const createDG = async () => {
    let endpoint = config.endpoint + "/dg/"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(dgData),
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/admin/dg/details?dg_id=" + data.data.id)
      notify({ message: "DG Created", type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const updateDG = async () => {
    let endpoint = config.endpoint + "/dg/id/" + dg_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(dgData),
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/admin/dg/details?dg_id=" + dg_id)
      notify({ message: "DG Updated", type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const deleteDG = async () => {
    let endpoint = config.endpoint + "/dg/id/" + dg_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",

    })
    let data = await response.json()
    if (data.status === 200) {
      // Go back
      navigate(-1)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const addMember = async (member_id, role) => {
    let endpoint = config.endpoint + "/dg/members/"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        dg_id: dg_id,
        user_id: member_id,
        role: role
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchDGMembers()
      fetchDGLeaders()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const removeMember = async (member_id) => {
    let endpoint = config.endpoint + "/dg/members/" + dg_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id: member_id,
        dg_id: dg_id
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchDGMembers()
      fetchDGLeaders()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const updateMember = async (member_id, role) => {
    let endpoint = config.endpoint + "/dg/members/" + dg_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id: member_id,
        dg_id: dg_id,
        role: role
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchDGMembers()
      fetchDGLeaders()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const fetchPlatformUsers = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/users/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setUsers(data.data)
      console.log(data.data)
    }
    setLoading(false)
  }

  const ROLES = [
    { label: "Member", value: 0 },
    { label: "DG Core", value: 1 },
  ]

  return (
    <AdminLayout
      navSelected={"Disciple Groups"}
      regularContainer={dg_id !== "new"}
    >
      {/* Adding User Modal */}
      <ModalLayout isVisible={showMemberModal} setIsVisible={setShowMemberModal} modalTitle="Add Member">
        <div className="input-container">
          <label htmlFor="" className="input-label">User</label>
          <Select
            options={users.map(user => ({ label: user.first_name + " " + user.last_name, value: user.id }))}
            onChange={(e) => {
              setTempUserData(e)
            }}
          />
        </div>
        <div className="input-container">
          <label htmlFor="" className="input-label">Role</label>
          <Select
            options={ROLES}
            onChange={(e) => {
              setTempUserData({ ...tempUserData, role: e.value })
            }}
          />
        </div>
        <div className="flex-row flex gap-2 alignEnd">
          <button className="button"
            onClick={() => {
              addMember(tempUserData.value, tempUserData.role)
              setShowMemberModal(false)
            }}
          >Add</button>
        </div>
      </ModalLayout>

      <div className="flex-row flex gap-2 alignStart">
        <div className={dg_id === "new" ? "w-100" : "w-70"}>
          <div className="card">
            <p className="card-title">{dg_id === "new" ? "Create" : "Edit"} Disciple Group</p>
            {dg_id !== "new" && <div className="input-container">
              <label htmlFor="" className="input-label">DG Group Photo</label>
              <FileUploadField
                upload_url={config.endpoint + "/file/dg/cover/id/" + dg_id}
                max_files={1}
                item_index={1}
                text={"Upload Photo"}
                max_file_size={"5"}
                onChange={(files) => { }}
              />
            </div>}
            <div className="flex-row flex gap-2 items-center">
              <div className="input-container">
                <label htmlFor="" className="input-label">Name</label>
                <input type="text" className="text-input" placeholder='e.g. Truth Seekers' value={dgData.name} onChange={(e) => setDGData({ ...dgData, name: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Cluster</label>
                <Select
                  options={dgClusterData.map(cluster => ({ label: cluster.name, value: cluster.id }))}
                  value={dgClusterData.map(cluster => ({ label: cluster.name, value: cluster.id })).find(cluster => cluster.value == dgData.cluster_id)}
                  onChange={(e) => setDGData({ ...dgData, cluster_id: e.value })}
                />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Language</label>
                <Select
                  options={LANGUAGE}
                  value={LANGUAGE.find(lang => lang.value === dgData.language)}
                  onChange={(e) => setDGData({ ...dgData, language: e.value })}
                />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Tagline</label>
              <input type="text" className="text-input" placeholder='e.g. Seeking the lost' value={dgData.tagline} onChange={(e) => setDGData({ ...dgData, tagline: e.target.value })} />
            </div>
            <div className="flex-row flex gap-2 items-center">
              <div className="input-container">
                <label htmlFor="" className="input-label">Meeting Day</label>
                <Select
                  options={DAYS}
                  value={DAYS.find(day => day.value === dgData.day)}
                  onChange={(e) => setDGData({ ...dgData, day: e.value })}
                />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Time</label>
                <input type="time" className="text-input" value={dgData.datetime} onChange={(e) => setDGData({ ...dgData, datetime: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Status</label>
                <div className="flex-row flex gap-2 items-center">
                  <p className="input-label" style={{ whiteSpace: "nowrap", fontWeight: dgData.is_accepting_members == 0 ? "bold" : "normal" }}>Closed</p>
                  <Switch
                    checked={dgData.is_accepting_members}
                    onChange={(e) => setDGData({ ...dgData, is_accepting_members: e ? 1 : 0 })}
                  />
                  <p className="input-label" style={{ whiteSpace: "nowrap", fontWeight: dgData.is_accepting_members == 1 ? "bold" : "normal" }}>Open to new member</p>
                </div>
              </div>
            </div>
            <div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Tags</label>
                <input type="text" className="text-input" placeholder='e.g. Bible Study, Prayer, Youth' value={temptags} onChange={(e) => {
                  if (e.target.value.includes(",")) {
                    if (dgData.tags === undefined || dgData.tags === null|| dgData.tags.length === 0) {
                      setDGData({ ...dgData, tags: [temptags] })
                    } else {
                      setDGData({ ...dgData, tags: [...dgData.tags, e.target.value.split(",")[0]] })
                    }
                    setTemptags("")
                  }
                  else {
                    setTemptags(e.target.value)
                  }
                }} />
              </div>
              <div className="flex-row flex gap-2 items-center">
                {dgData?.tags?.length > 0 && dgData?.tags?.map((tag, index) => (
                  <div key={index} className="chip"
                    onClick={() => {
                      let tempTags = dgData.tags
                      tempTags.splice(index, 1)
                      setDGData({ ...dgData, tags: tempTags })
                    }}
                  >{tag}</div>
                ))}
              </div>
            </div>
            <div className="input-container mt-4">
              <label htmlFor="" className="input-label">Description</label>
              <textarea className="textarea-input"
                value={dgData.description}
                onChange={(e) => setDGData({ ...dgData, description: e.target.value })}
                placeholder='e.g. "We are a group of young adults who meet every Sunday to discuss the word of God and pray together."'
              />
            </div>

            <div className="mt-10 column right alignEnd">
              <div className="flex-row flex gap-2 items-center">
                {dg_id !== "new" &&
                  <button className="button-delete"
                    onClick={() => {
                      deleteDG()
                    }}
                  >Delete</button>
                }
                <button className="button"
                  onClick={() => {
                    if (dg_id === "new") {
                      createDG()
                    } else {
                      updateDG()
                    }
                  }}
                >{dg_id === "new" ? "Create" : "Update"}</button>
              </div>
            </div>
          </div>
        </div>
        {dg_id !== "new" && (<div className='w-30'>
          <div className="card">
            <p className="card-title">DG Members</p>
            <div className="flex-row flex w-100 items-center justify-between">
              <p className="card-subtitle">DG Core</p>

            </div>
            {dgLeaders?.length > 0 ? dgLeaders?.map((leader, index) => (
              <div key={index} className="flex-row flex gap-2 items-center justify-between mb-2 cursor-pointer">
                <p style={{ fontSize: 14 }} onClick={() => {
                  navigate("/admin/users/details?user_id=" + leader.user_id)
                }}
                  className='cursor-pointer'>{leader?.user_data?.first_name} {leader?.user_data?.last_name}</p>
                <Select
                  options={ROLES}
                  value={ROLES.find(role => role.value == leader.role)}
                  onChange={(e) => updateMember(leader.user_id, e.value)}
                />
              </div>
            )) :
              <p className="card-subtitle" style={{ fontStyle: "italic", fontWeight: "900" }}>No DG Core</p>
            }

            <div style={{ width: "100%", height: 2, backgroundColor: "gray", marginBlock: 10 }} />
            <div className="flex-row flex w-100 items-center justify-between mb-4">
              <p className="card-subtitle">Members</p>
              <button className="backgroundLess row gap-2 items-center button-cta"
                onClick={() => {
                  setShowMemberModal(true)
                }}
              >
                <MdPersonAdd size={12} />
                <p className="small">member</p>
              </button>
            </div>
            {dgMembers?.length > 0 ? dgMembers?.map((member, index) => {
              if (member.role == 1) return
              return(
              <div key={index} className="flex-row flex gap-2 items-center justify-between mb-2 cursor-pointer">
                <p style={{ fontSize: 14 }}
                  onClick={() => {
                    navigate("/admin/users/details?user_id=" + member.user_id)
                  }} className='cursor-pointer'>{member?.user_data?.first_name} {member?.user_data?.last_name}</p>
                <Select
                  options={ROLES}
                  value={ROLES.find(role => role.value == member.role)}
                  onChange={(e) => updateMember(member.user_id, e.value)}
                />
                <button
                  className="button-delete"
                  onClick={() => {
                    removeMember(member.user_id)
                  }}
                >Remove</button>
              </div>
              )
            }) :
              <p className="card-subtitle" style={{ fontStyle: "italic", fontWeight: "900" }}>No Members</p>
            }
          </div>
        </div>)}
      </div>
      {/* <pre>{JSON.stringify(dgMembers,null,2)}</pre>
      <pre>{JSON.stringify(dgLeaders,null,2)}</pre> */}
    </AdminLayout>
  )
}
