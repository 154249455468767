import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import { MdAdd } from 'react-icons/md';
import { tidyDate, tidyDateRaw } from '../../utils/helper';
import notify from '../../utils/IANotification';

export default function AnnouncementPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [announcements, setAnnouncements] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")

  useEffect(() => {
    fetchAnnouncements()
  }, [])

  const fetchAnnouncements = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/announcements/admin"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setAnnouncements(data.data?.reverse())
    }
    setLoading(false)
  }


  const duplicateAnnouncement = async (announcement_id) => {
    let endpoint = config.endpoint + "/announcements/duplicate"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        announcement_id: announcement_id
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchAnnouncements()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  return (
    <AdminLayout
      navSelected={"Announcements"}
      toolbar={
        <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Announcements</h1>
          <button
            className="button flex-row items-center flex gap-2"
            onClick={() => {
              navigate("/admin/announcements/details?announcement_id=new")
            }}
          >
            <MdAdd size={15} />
            Announcement
          </button>
        </div>
      }
    >
      <div className="column items-center justify-between w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>


      {loading ? (
        <div className="flex-row items-center flex justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {announcements.map((announcement, index) => {
            if (!JSON.stringify(announcement).toLowerCase().includes(query.toLowerCase())) return null
            return (
              <div
                key={index}
                className="flex-row items-center flex justify-between w-100 card mt-10" style={{ padding: 0 }}
              >
                <img src={announcement.file_data?.ext_file_path ? `${config.endpoint}${announcement.file_data?.ext_file_path}` : "/default/default.jpg"} alt="announcement" className="card-image" style={{ width: 100, height: 100, objectFit:"cover" }} />
                <div className="flex-row flex gap-2 items-center p-all w-100 justify-between">
                  <div className="">

                    <h3>{announcement.title}</h3>
                    <h3 className='card-subtitle'>{announcement?.is_forever == 1 ? "No end date" : `${tidyDate(announcement.start_datetime)} - ${tidyDate(announcement.end_datetime)}`}</h3>
                  </div>
                  <div className="flex-row flex gap-2 items-center">
                    <button
                      className="backgroundLess"
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        duplicateAnnouncement(announcement.id)
                      }}
                    >
                      Duplicate
                    </button>
                    <button
                      className="backgroundLess"
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        navigate("/admin/announcements/details?announcement_id=" + announcement.id)
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}
