import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import Switch from 'react-switch'
import { MdAdd, MdEmail, MdFacebook, MdGroups2, MdLocationOn, MdPhone, MdPolicy, MdTimer, MdWeb } from 'react-icons/md'
import Select from 'react-select'
import { capitaliseFirstLetter } from '../../utils/helper'
import AdminSettingsNavbar from '../../components/AdminSettingsNavbar';
import FileUploadField from '../../components/FileUploadField';
import { IoLogoInstagram } from 'react-icons/io5';

export default function SettingsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const state = new URLSearchParams(window.location.search).get("state")
  const role = new URLSearchParams(window.location.search).get("role")

  const [generalSettings, setGeneralSettings] = useState({
    session_timeout: 0,
    max_login_attempts: 0,
    allow_login_student: "0",
    allow_login_parent: "0",
    allow_login_staff: "0",
    show_announcement_banner: "0",
    announcement_banner: "",
    contact_forward_email: "",
    library_loan_period: "",
    library_loan_limit: "",
    library_renewal_limit: "",
  })

  const SERVICE_DAYS = [
    { value: "0", label: "Sunday" },
    { value: "1", label: "Monday" },
    { value: "2", label: "Tuesday" },
    { value: "3", label: "Wednesday" },
    { value: "4", label: "Thursday" },
    { value: "5", label: "Friday" },
    { value: "6", label: "Saturday" },
  ]

  const [policySettings, setPolicySettings] = useState([])
  const [policyData, setPolicyData] = useState("")
  const [contactSettings, setContactSettings] = useState([])
  const [usersData, setUsersData] = useState([])
  const [serviceData, setServiceData] = useState({})
  const [serviceSettings, setServiceSettings] = useState([])

  const [userSearch, setUserSearch] = useState("")

  const LINK_TYPE = [
    {
      type: "email",
      name: "Email Address",
      icon: <MdEmail size={20} />,
    },
    {
      type: "phone",
      name: "Contact Number",
      icon: <MdPhone size={20} />,
    },
    {
      type: "website",
      name: "Website links",
      icon: <MdWeb size={20} />,
    },
    {
      type: "address",
      name: "Address",
      icon: <MdLocationOn size={20} />,
    },
    {
      type: "facebook",
      name: "Facebook",
      icon: <MdFacebook size={20} />,
    },

    {
      type: "instagram",
      name: "Instagram",
      icon: <IoLogoInstagram size={20} />,
    },
  ]


  const saveGeneralSettings = async () => {
    if (generalSettings.session_timeout <= 60000) {
      notify({ message: "Session Timeout must be at least 1 minute", type: "warning" })
      return
    }
    if (generalSettings.max_login_attempts <= 0) {
      notify({ message: "Max Login Attempts must be at least 1", type: "warning" })
      return
    }

    let endpoint = config.endpoint + "/config/general"
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...generalSettings,
        session_timeout: parseInt(generalSettings.session_timeout),
        max_login_attempts: parseInt(generalSettings.max_login_attempts),
      }),
      credentials: "include",

    })

    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "General settings saved", type: "success" })
    } else {
      notify({ message: "An error occurred", type: "error" })
    }
  }


  const savePolicySettings = async () => {
    let endpoint = config.endpoint + "/config/policy"
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        policy: policySettings,
      }),
      credentials: "include",
    })

    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Policy settings saved", type: "success" })
    } else {
      notify({ message: "An error occurred", type: "error" })
    }
  }

  const saveServiceSettings = async () => {
    let endpoint = config.endpoint + "/config/services"
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        service: serviceSettings,
      }),
      credentials: "include",
    })

    let data = await fetchResponse.json()
    if (data.status === 200) {
      fetchConfigSettings()
      notify({ message: "Service settings saved", type: "success" })
    } else {
      notify({ message: "An error occurred", type: "error" })
    }
  }

  const saveContactSettings = async () => {
    let endpoint = config.endpoint + "/config/contact"
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contact: contactSettings,
      }),
      credentials: "include",
    })

    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Contact settings saved", type: "success" })
    } else {
      notify({ message: "An error occurred", type: "error" })
    }
  }


  const fetchConfigSettings = async () => {
    let endpoint = config.endpoint + "/config"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })

    let data = await fetchResponse.json()
    if (data.status === 200) {
      console.log(data)
      setGeneralSettings(data?.data?.general)
      setPolicySettings([...data?.data?.policy])
      let links = data?.data?.links
      links.forEach(link => {
        link.is_editing = "0"
      })
      setContactSettings(links)
      let services = data?.data?.services
      services.forEach(service => {
        service.is_editing = "0"
      })
      setServiceSettings(services)
    } else {
      notify({ message: "An error occurred", type: "error" })
    }
  }

  const fetchAllUsers = async () => {
    let endpoint = config.endpoint + "/auth/users"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })

    let data = await fetchResponse.json()
    if (data.status === 200) {
      setUsersData(data?.data)
    } else {
      notify({ message: "An error occurred", type: "error" })
    }
  }

  const calculateTime = (time) => {
    let minutes = time / 60000
    let hours = minutes / 60
    let days = hours / 24
    if (days >= 1) {
      return days + " days"
    } else if (hours >= 1) {
      return hours + " hours"
    } else {
      return minutes + " minutes"
    }
  }

  useEffect(() => {
    // fetchAllUsers()
    fetchConfigSettings()
  }, [])

  return (
    <AdminLayout
      navSelected={"Settings"}
    >
      <AdminSettingsNavbar selected={state} />

      {state === "general" &&
        <div className='container center items-center'>
          <div className="inner-container">
            <p>General Settings</p>
            {/* <div className="card mt-4">
              <p className="card-title">Login</p>
              <div className="input-container">
                <label htmlFor="" className="input-label">Session Timeout ({calculateTime(generalSettings?.session_timeout)})</label>
                <input
                  type="number"
                  className="text-input"
                  value={generalSettings.session_timeout}
                  onChange={e => setGeneralSettings({ ...generalSettings, session_timeout: e.target.value })}
                />
              </div>
              <div className="input-container mt-10">
                <label htmlFor="" className="input-label">Max Login Attempts</label>
                <input
                  type="number"
                  className="text-input"
                  value={generalSettings.max_login_attempts}
                  onChange={e => setGeneralSettings({ ...generalSettings, max_login_attempts: e.target.value })}
                />
              </div>
              <>
              <div className="w-100 flex-row items-center flex justify-between mt-10">
                <p className="input-label">Allow Student Login</p>
                <Switch
                  checked={generalSettings.allow_login_student == "1"}
                  onChange={checked => {
                    setGeneralSettings({ ...generalSettings, allow_login_student: checked ? "1" : "0" })
                  }}
                />
              </div>
              <div className="w-100 flex-row items-center flex justify-between mt-10">
                <p className="input-label">Allow Parent Login</p>
                <Switch
                  checked={generalSettings.allow_login_parent == "1"}
                  onChange={checked => {
                    setGeneralSettings({ ...generalSettings, allow_login_parent: checked ? "1" : "0" })
                  }}
                />
              </div>
              <div className="w-100 flex-row items-center flex justify-between mt-10">
                <p className="input-label">Allow Staff Login</p>
                <Switch
                  checked={generalSettings.allow_login_staff == "1"}
                  onChange={checked => {
                    setGeneralSettings({ ...generalSettings, allow_login_staff: checked ? "1" : "0" })
                  }}
                />
              </div>
              </>
            </div> */}

            <div className="card mt-10">
              <p className="card-title">Feedback</p>
              <div className="flex-row items-center flex justify-between">
                <p className="input-label">Allow Feedback</p>
                <Switch
                  checked={generalSettings.allow_feedback == "1"}
                  onChange={checked => {
                    setGeneralSettings({ ...generalSettings, allow_feedback: checked ? "1" : "0" })
                  }}
                />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Feedback Link</label>
                <input
                  type="text"
                  className="text-input"
                  value={generalSettings.feedback_link}
                  placeholder='E.g. https://forms.gle/1234567890'
                  onChange={e => setGeneralSettings({ ...generalSettings, feedback_link: e.target.value })}
                />
              </div>
            </div>

            <div className="card mt-10">
              <p className="card-title">Mobile Application</p>
              <div className="input-container">
                <label htmlFor="" className="input-label">Apple App Store (iOS)</label>
                <input
                  type="text"
                  className="text-input"
                  value={generalSettings.ios_link}
                  placeholder='E.g. https://apps.apple.com/us/app/bmc/id1234567890'
                  onChange={e => setGeneralSettings({ ...generalSettings, ios_link: e.target.value })}
                />
              </div>
              <div className="input-container mt-10">
                <label htmlFor="" className="input-label">Google Play Store link (Android)</label>
                <input
                  type="text"
                  className="text-input"
                  value={generalSettings.android_link}
                  placeholder='E.g. https://play.google.com/store/apps/details?id=com.bmc'
                  onChange={e => setGeneralSettings({ ...generalSettings, android_link: e.target.value })}
                />
              </div>
            </div>

            <div className="card mt-10">
              <p className="card-title">Contact Forms</p>
              <div className="input-container">
                <label htmlFor="" className="input-label">Forward Email</label>
                <input
                  type="email"
                  className="text-input"
                  value={generalSettings.contact_forward_email}
                  placeholder='E.g. hello@church.com'
                  onChange={e => setGeneralSettings({ ...generalSettings, contact_forward_email: e.target.value })}
                />
              </div>
            </div>

            <div className="card mt-10">
              <p className="card-title">Library</p>
              <div className="input-container">
                <label htmlFor="" className="input-label">Loan Period (Days)</label>
                <input
                  type="text"
                  className="text-input"
                  value={generalSettings.library_loan_period}
                  placeholder='E.g. 14'
                  onChange={e => setGeneralSettings({ ...generalSettings, library_loan_period: e.target.value })}
                />
              </div>
              <div className="input-container mt-10">
                <label htmlFor="" className="input-label">Loan Limit</label>
                <input
                  type="text"
                  className="text-input"
                  value={generalSettings.library_loan_limit}
                  placeholder='E.g. 5'
                  onChange={e => setGeneralSettings({ ...generalSettings, library_loan_limit: e.target.value })}
                />
              </div>
              <div className="input-container mt-10">
                <label htmlFor="" className="input-label">Renewal Limit</label>
                <input
                  type="text"
                  className="text-input"
                  value={generalSettings.library_renewal_limit}
                  placeholder='E.g. 2'
                  onChange={e => setGeneralSettings({ ...generalSettings, library_renewal_limit: e.target.value })}
                />
              </div>
            </div>

            <div className="card mt-10">
              <p className="card-title">Announcement Banner</p>
              <div className="w-100 flex-row items-center flex justify-between">
                <p className="input-label">Visibility</p>
                <Switch
                  checked={generalSettings.show_announcement_banner == "1"}
                  onChange={checked => {
                    setGeneralSettings({ ...generalSettings, show_announcement_banner: checked ? "1" : "0" })
                  }}
                />
              </div>

              <div className="input-container mt-10">
                <label htmlFor="" className="input-label">Banner</label>
                <textarea
                  className="textarea-input"
                  value={generalSettings.announcement_banner}
                  placeholder='E.g. Welcome BMC! We are excited to have you here.'
                  onChange={e => setGeneralSettings({ ...generalSettings, announcement_banner: e.target.value })}
                />
              </div>
            </div>


            <div className="column right alignEnd mt-30">
              <button className="button"
                onClick={() => saveGeneralSettings()}
              >Save</button>
            </div>
          </div>
        </div>
      }

      {state === "policy" &&
        <div className='container center items-center'>
          <div className="inner-container">
            <p>Policy Settings</p>
            <div className="mt-4 flex-row items-center flex gap-2">
              <button className="button flex-row items-center flex gap-2"
                style={{ whiteSpace: "nowrap", minWidth: 150 }}
                onClick={() => {
                  let temp = [...policySettings]
                  temp.push({
                    name: "New Policy",
                    content: "",
                    identifier: Math.random().toString(36).substring(7)
                  })
                  setPolicyData(temp[temp.length - 1].identifier)
                  setPolicySettings(temp)
                }}
              >
                <MdAdd size={20} />
                Add Policy
              </button>

              <div style={{ backgroundColor: "gray", height: "35px", width: 2 }} />

              <div className="flex-row flex gap-2 items-center hide-scrollbars" style={{ overflowY: "scroll" }}>
                {policySettings?.length > 0 && policySettings.map((policy, index) => {
                  return (
                    <div className="cursor-pointer"
                      key={index}
                      onClick={() => {
                        setPolicyData(policy?.identifier || policy?.id)
                      }}
                      style={{
                        border: "2px solid purple", padding: "5px 10px", borderRadius: 25,
                        backgroundColor: policyData === policy?.identifier || policyData === policy?.id ? "purple" : "white",
                      }}
                    >
                      <p style={{ fontSize: 13, color: policyData === policy?.identifier || policyData === policy?.id ? "white" : "black", whiteSpace: "nowrap" }}>{policy?.name || "Policy"}</p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="card mt-10">
              {/* <pre>{JSON.stringify(policySettings)}</pre> */}
              {/* <pre>{JSON.stringify(policyData)}</pre> */}
              {policyData ? <>
                {/* <p className="card-title">Policy</p> */}
                <div className="input-container">
                  <label htmlFor="" className="input-label">Policy</label>
                  <input type="text" className="text-input"
                    value={policySettings?.find(policy => policy?.identifier === policyData || policy?.id === policyData)?.name}
                    placeholder='E.g. Classroom Policy'
                    onChange={e => {
                      let temp = [...policySettings]
                      temp.find(policy => policy?.identifier === policyData || policy?.id === policyData).name = e.target.value
                      setPolicySettings(temp)
                    }} />
                </div>
                <div className="input-container mt-10">
                  <label htmlFor="" className="input-label">Content</label>
                  <textarea
                    className="textarea-input"
                    value={policySettings?.find(policy => policy?.identifier === policyData || policy?.id === policyData)?.content}
                    placeholder='E.g. 1. Respect your classmates'
                    onChange={e => {
                      let temp = [...policySettings]
                      temp.find(policy => policy?.identifier === policyData || policy?.id === policyData).content = e.target.value
                      setPolicySettings(temp)
                    }}
                  />
                </div>
                {policyData &&
                  policySettings?.find(policy => policy?.identifier === policyData || policy?.id === policyData)?.id &&
                  <div className="column right alignEnd">
                    <button className="button-delete"
                      onClick={() => {
                        let temp = [...policySettings]
                        temp = temp.filter(policy => policy?.id != policyData)
                        setPolicySettings(temp)
                        setPolicyData("")
                      }}
                    >Delete Policy</button>
                  </div>}
              </>
                :
                <div className="column center items-center">
                  <MdPolicy size={50} color="gray" />
                  <p style={{ color: "gray" }}>Select a policy or create a policy to continue</p>
                </div>
              }
            </div>

            <div className="column right alignEnd mt-30">
              <button className="button"
                onClick={() => savePolicySettings()}
              >Save</button>
            </div>
          </div>
        </div>
      }


      {state === "contact" &&
        <div className='container center items-center'>
          <div className="inner-container">
            <p>Contact Information</p>

            {LINK_TYPE.map((type, index) => {
              return (
                <div className="card mt-10">
                  <div className="flex-row items-center flex justify-between">
                    <p className="card-title row gap-2 items-center">
                      {type.icon}
                      {type.name}
                    </p>
                    <button className="backgroundLess gap-2 items-center row" style={{ fontSize: 12 }}
                      onClick={() => {
                        let temp = [...contactSettings]
                        temp.forEach((contact, index) => {
                          temp[index].is_editing = "0"
                        })
                        temp.push({
                          link_type: type.type,
                          link_name: "New " + type.name,
                          link_url: "",
                          is_public: "1",
                          is_editing: "1",
                        })

                        setContactSettings(temp)
                      }}
                    >
                      <MdAdd size={15} />
                      {type.name}
                    </button>
                  </div>
                  {contactSettings?.length > 0 && contactSettings.map((contact, index) => {
                    if (contact.link_type !== type.type) return null
                    return (
                      <>
                        {contact?.is_editing === "0" ?
                          <div className="flex-row items-center flex justify-between mt-10 cursor-pointer hover-card" style={{ paddingInline: 10, borderBottom: "1px solid lightgray", paddingBottom: 10 }}
                            onClick={() => {
                              let temp = [...contactSettings]
                              temp.forEach((contact, index) => {
                                temp[index].is_editing = "0"
                              })
                              temp[index].is_editing = "1"
                              setContactSettings(temp)
                            }}
                          >
                            <div>
                              <p style={{ fontSize: 13, fontWeight: "900", color: "black" }}>{contact?.link_name}</p>
                              <p style={{ fontSize: 15, fontWeight: "normal", color: "gray", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", flexGrow: 0, flexShrink: 1, maxWidth: 300 }}>{contact?.link_url}</p>
                            </div>
                            <button className="backgroundLess"
                              onClick={() => {
                                let temp = [...contactSettings]
                                temp.forEach((contact, index) => {
                                  temp[index].is_editing = "0"
                                })
                                temp[index].is_editing = "1"
                                setContactSettings(temp)
                              }}
                              style={{ fontSize: 12 }}
                            >
                              Edit
                            </button>
                          </div>
                          :
                          <div className="mt-10">
                            <p className="card-title mb-4">Edit</p>
                            <div className="input-container">
                              <label htmlFor="" className="input-label">Name</label>
                              <input type="text" className="text-input"
                                value={contact?.link_name}
                                placeholder='E.g. Main Email'
                                onChange={e => {
                                  let temp = [...contactSettings]
                                  temp[index].link_name = e.target.value
                                  setContactSettings(temp)
                                }} />
                            </div>
                            <div className="input-container">
                              <label htmlFor="" className="input-label">Link</label>
                              <input
                                className="text-input mt-10"
                                style={{ textOverflow: "ellipsis" }}
                                value={contact?.link_url}
                                type={type.type === "phone" ? "tel" : type.type === "email" ? "email" : "text"}
                                placeholder={type.type === "phone" ? "E.g. +1234567890" : type.type === "email" ? "E.g. hello@bmc.org.sg" : type.type === "website" ? "E.g. https://church.com" : "Link/Text"}
                                onChange={e => {
                                  let temp = [...contactSettings]
                                  temp[index].link_url = e.target.value
                                  setContactSettings(temp)
                                }} />
                            </div>
                            <div className="column">

                              <p className="input-label mb-2">Public Link</p>
                              <Switch
                                checked={contact?.is_public == "1"}
                                onChange={checked => {
                                  let temp = [...contactSettings]
                                  temp[index].is_public = checked ? "1" : "0"
                                  setContactSettings(temp)
                                }}
                              />
                            </div>
                            <div className="column right alignEnd mt-10">
                              <div className="flex-row flex gap-2 items-center">
                                <button className="button-delete"
                                  onClick={() => {
                                    let temp = [...contactSettings]
                                    temp = temp.filter(contact => contact?.id != contact?.id)
                                    setContactSettings(temp)
                                  }}
                                >Delete</button>
                                <button className="button"
                                  onClick={() => {
                                    let temp = [...contactSettings]
                                    temp[index].is_editing = "0"
                                    setContactSettings(temp)
                                    saveContactSettings()
                                  }}
                                >Save</button>
                              </div>
                            </div>
                          </div>
                        }
                      </>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      }
      {state === "service" &&
        <div className="container center items-center">
          <div className="inner-container">
            <div className="flex-row flex gap-2 justify-between items-center w-100 mt-10 mb-4">
              <p>Services</p>
              <button className="button flex-row items-center flex gap-2"
                style={{ whiteSpace: "nowrap", minWidth: 150 }}
                onClick={() => {
                  let temp = [...serviceSettings]
                  temp.push({
                    name: "New Service",
                    description: "",
                    identifier: Math.random().toString(36).substring(7)
                  })
                  setServiceSettings(temp)
                }}
              >
                <MdAdd size={20} />
                Add Service
              </button>
            </div>
            {serviceSettings?.length > 0 && serviceSettings.map((service, index) => {
              return (
                <div className="card mt-10">
                  <div className="flex-row items-center flex justify-between mt-10 cursor-pointer hover-card" style={{ paddingInline: 10, borderBottom: "0px solid lightgray", paddingBottom: 10 }}
                    onClick={() => {
                      let temp = [...serviceSettings]
                      temp.forEach((service, index) => {
                        temp[index].is_editing = "0"
                      })
                      temp[index].is_editing = "1"
                      setServiceSettings(temp)
                    }}
                  >
                    <div>
                      <p style={{ fontSize: 13, fontWeight: "900", color: "black" }}>{service?.name}</p>
                      <div className="flex-row flex gap-4 items-center">
                        <div className="flex-row flex gap-2 items-center">
                          <MdTimer size={13} color='gray' />
                          <p className='card-subtitle gray'>{service?.datetime}</p>
                        </div>
                        <div className="flex-row flex gap-2 items-center">
                          <MdGroups2 size={13} color='gray' />
                          <p className='card-subtitle gray'>{service?.minimum_age} +</p>
                        </div>
                      </div>
                    </div>
                    <button className="backgroundLess"
                      onClick={() => {
                        let temp = [...serviceSettings]
                        temp.forEach((service, index) => {
                          temp[index].is_editing = "0"
                        })
                        temp[index].is_editing = "1"
                        setServiceSettings(temp)
                      }}
                      style={{ fontSize: 12 }}
                    >
                      Edit
                    </button>
                  </div>
                  {service?.is_editing === "1" &&
                    <div className="mt-10">
                      <p className="card-title mb-4">Edit</p>
                      {service?.id &&
                        <FileUploadField
                          text="Upload Header Image"
                          upload_url={config.endpoint + "/file/services/type/id/" + service?.id}
                          subtext=""
                          max_file_size={"5"}
                          max_files={1}
                          onChange={(files) => { fetchConfigSettings() }}
                        />
                      }
                      <div className="flex-row flex gap-2 items-center justify-between">
                        <div className="input-container w-70">
                          <label htmlFor="" className="input-label">Name</label>
                          <input type="text" className="text-input"
                            value={service?.name}
                            placeholder='E.g. Traditional Service'
                            onChange={e => {
                              let temp = [...serviceSettings]
                              temp[index].name = e.target.value
                              setServiceSettings(temp)
                            }} />
                        </div>
                        <div className="input-container w-30">
                          <label htmlFor="" className="input-label">Age</label>
                          <input type="number" className="text-input"
                            value={service?.minimum_age}
                            placeholder='E.g. 35'
                            onChange={e => {
                              let temp = [...serviceSettings]
                              temp[index].minimum_age = e.target.value
                              setServiceSettings(temp)
                            }} />
                        </div>
                      </div>
                      <div className="flex-row flex gap-2 items-center justify-between">
                        <div className="input-container">
                          <label htmlFor="" className="input-label">Service Timing</label>
                          <input type="time" className="text-input"
                            value={service?.datetime}
                            placeholder='E.g. 8.30am'
                            onChange={e => {
                              let temp = [...serviceSettings]
                              temp[index].datetime = e.target.value
                              setServiceSettings(temp)
                            }} />
                        </div>
                        <div className="input-container">
                          <label htmlFor="" className="input-label">Day</label>
                          <Select
                            options={SERVICE_DAYS}
                            value={SERVICE_DAYS.find(day => day.value === service?.day)}
                            onChange={e => {
                              let temp = [...serviceSettings]
                              temp[index].day = e.value
                              setServiceSettings(temp)
                            }}
                          />
                        </div>
                      </div>
                      <div className="input-container">
                        <label htmlFor="" className="input-label">Description</label>
                        <textarea
                          className="textarea-input"
                          value={service?.description}
                          placeholder='E.g. Description of Service'
                          onChange={e => {
                            let temp = [...serviceSettings]
                            temp[index].description = e.target.value
                            setServiceSettings(temp)
                          }}
                        />
                      </div>
                      <div className="column right alignEnd mt-10">
                        <div className="flex-row flex gap-2 items-center">
                          <button className="button-delete"
                            onClick={() => {
                              let temp = [...serviceSettings]
                              temp = temp.filter(service => service?.id != service?.id)
                              setServiceSettings(temp)
                            }}
                          >Delete</button>
                          <button className="button"
                            onClick={() => {
                              let temp = [...serviceSettings]
                              temp[index].is_editing = "0"
                              setServiceSettings(temp)
                              saveServiceSettings()
                            }}
                          >Save</button>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              )
            })}

          </div>
        </div>
      }

      {/* <>
      {state === "access" &&
        <div className="container center items-center">
          <div className="inner-container">
            <p>Users</p>
            <div className="flex-row flex gap-2 alignStart mt-10 mb-4">
              <input type="text" className="text-input w-100"
                value={userSearch}
                onChange={e => setUserSearch(e.target.value)}
                placeholder='Search...'
                id='search-input'
              />
              <Select
                options={
                  usersData?.length > 0 ?
                    [
                      ...new Set(usersData.map(user => user.account_type))
                    ].map(role => {
                      return {
                        value: role,
                        label: capitaliseFirstLetter(role)
                      }
                    }) : []
                }
                styles={
                  {
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid lightgray",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid lightgray",
                      }
                    }),
                    container: (base, state) => ({
                      ...base,
                      width: 200,
                      height: document.getElementById("search-input")?.clientHeight,
                    })
                  }
                }
                value={role ? { value: role, label: capitaliseFirstLetter(role) } : null}
                onChange={e => {
                  navigate("/admin/settings?state=access&role=" + e.value)
                }}
              />
            </div>

            {usersData?.length > 0 &&
              usersData.map((user, index) => {
                if (userSearch && !JSON.stringify(user).toLowerCase().includes(userSearch.toLowerCase())) return null
                if (role && user.account_type !== role) return null
                let bgColor, fgColor
                if (user.account_type === "admin" || user.account_type === "superadmin") {
                  bgColor = "purple"
                  fgColor = "white"
                } else if (user.account_type === "staff") {
                  bgColor = "orange"
                  fgColor = "white"
                } else if (user.account_type === "student") {
                  bgColor = "green"
                  fgColor = "white"
                } else if (user.account_type === "parent") {
                  bgColor = "blue"
                  fgColor = "white"
                } else {
                  bgColor = "gray"
                  fgColor = "white"
                }

                return (
                  <div className="card mt-10 cursor-pointer row justify-between items-center"
                    // style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10, borderLeft: "5px solid purple" }}
                    style={{ borderLeft: "4px solid #0222FF", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}
                    onClick={() => {
                      navigate("/admin/user?user_id=" + user.id)
                    }}
                  >
                    <div style={{ maxWidth: 200, textOverflow: "ellipsis", overflowX: "hidden" }}>
                      <p style={{ fontSize: 13, fontWeight: "900", color: "black" }}>{user.first_name} {user.last_name}</p>
                      <p style={{ fontSize: 15, fontWeight: "normal", color: "gray" }}>{user.email}</p>
                    </div>
                    <p style={{ backgroundColor: bgColor, color: fgColor, padding: "5px 15px", borderRadius: 25, fontWeight: "900", fontSize: 13 }}>{user.account_type}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
      </> */}

    </AdminLayout>
  )
}
