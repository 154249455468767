import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import Switch from 'react-switch'
import Select from 'react-select'
import notify from '../../utils/IANotification';
import JoditEditor from 'jodit-react';
import { MdAdd, MdDelete, MdEdit, MdOpenInBrowser } from 'react-icons/md';
import ModalLayout from '../../layouts/ModalLayout';
import { capitaliseFirstLetter } from '../../utils/helper';
import FileUploadField from '../../components/FileUploadField';


export default function ServicesFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [loading, setLoading] = useState(true)
  let service_id = new URLSearchParams(window.location.search).get("service_id")
  const [serviceTypeData, setServiceTypeData] = useState([])
  const [sermonSeries, setSermonSeries] = useState([])
  const [activeRunSheet, setActiveRunSheet] = useState(0)
  const [showAll, setShowAll] = useState(false)
  const [serviceFormData, setServiceFormData] = useState({
    service_title: "",
    service_type_id: "",
    sermon_series_id: "",
    service_start_datetime: "",
    service_end_datetime: "",
    video_link: "",
    sermon_outline: "",
    sermon_title: "",
    is_special_service: "",
    worship_songs: "",
    sermon_link: "",
    worship_link: "",
    is_published: "0",
    servers: [
      {
        name: "",
        role: "Worship"
      }
    ]
  })


  const [runsheetData, setRunsheetData] = useState([
    {
      runsheet_item: "Welcome to Church",
      start_time: "11:00",
      duration: "5", // in minutes
      item_code: "regular",
      internal_notes: "Lead the congregation in a time of welcome",
      description: "Welcome to church and thanks for joining us today!",
      is_public: "1"
    }
  ])
  const [seriesFormData, setSeriesFormData] = useState({
    title: "",
    description: "",
  })
  const [bulletin, setBulletinData] = useState([])
  const [specialServicesData, setSpecialServicesData] = useState([])
  const [showSeriesModal, setShowSeriesModal] = useState(false)
  const [serviceSettings, setServiceSettings] = useState([])
  const [documents, setDocuments] = useState([])

  const [deleteConfirmation, setDeleteConfirmation] = useState("")
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)

  const editor = useRef(null);

  const placeholder = "Start typing...";

  const runsheet_items = ["regular", "worship", "announcements", "sermon", "tithe", "prayer"]

  const liveConfig = {
    height: 500,
    placeholder,
    // set custom buttons
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'superscript',
      'subscript',
      'ul',
      'ol',
      'outdent',
      'indent',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      'table',
      'link',
      'undo',
      'redo',
      'eraser',
      'copyformat',
    ],
    askBeforePasteHTML: false,
    hotkeys: {
      redo: 'ctrl+z',
      undo: 'ctrl+y,ctrl+shift+z',
      indent: 'ctrl+]',
      outdent: 'ctrl+[',
      bold: 'ctrl+b',
      italic: 'ctrl+i',
      removeFormat: 'ctrl+shift+m',
      insertOrderedList: 'ctrl+shift+7',
      insertUnorderedList: 'ctrl+shift+8',
      openSearchDialog: 'ctrl+f',
      openReplaceDialog: 'ctrl+r',
    },
  };


  useEffect(() => {
    fetchServiceTypes()
    fetchServiceById()
    fetchSermonSeries()
    fetchBulletIn()
    fetchSpecialServices()
    fetchConfigSettings()
  }, [])


  const handleSubmit = () => {
    if (service_id === "new") {
      createService()
    } else {
      updateService()
    }
  }


  window.addEventListener("keydown", (e) => {
    // Submit the form when ctrl + s is pressed
    if (e.ctrlKey && e.key === "s") {
      handleSubmit()
    }
  })


  const fetchConfigSettings = async () => {
    let endpoint = config.endpoint + "/config"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })

    let data = await fetchResponse.json()
    if (data.status === 200) {
      setServiceSettings(data.data.services)
    } else {
      notify({ type: "error", message: "Failed to fetch config settings" })
    }
  }


  const fetchSpecialServices = async () => {
    let endpoint = config.endpoint + "/services/special/admin"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      let service_data = data.data
      service_data.sort((a, b) => {
        return new Date(b.service_start_datetime) - new Date(a.service_start_datetime)
      })
      setSpecialServicesData(service_data)
    }
  }


  const fetchBulletIn = async () => {
    let endpoint = config.endpoint + "/bulletin/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setBulletinData(data.data)
    }
  }

  const fetchServiceTypes = async () => {
    let endpoint = config.endpoint + "/config/services"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setServiceTypeData(data.data)
    }
  }

  const fetchServiceById = async () => {
    if (service_id !== "new") {
      setLoading(true)
      let endpoint = config.endpoint + "/services/id/" + service_id
      let response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      })
      let data = await response.json()
      if (data.status === 200) {
        setServiceFormData(data.data.service)
        setRunsheetData(data.data.runsheet)
        setDocuments(data.data.documents)
      }
    }
    setLoading(false)
  }

  const fetchSermonSeries = async () => {
    let endpoint = config.endpoint + "/sermon/series"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setSermonSeries(data.sermon_series)
    }
  }

  const createService = async () => {
    // if (!valid_runsheet()) return notify({ type: "error", message: "Please fill in all the runsheet details" })
    let endpoint = config.endpoint + "/services"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...serviceFormData,
        runsheet: runsheetData
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/admin/services/details?service_id=" + data.data.id)
      notify({ type: "success", message: "Successfully created service" })
    } else {
      notify({ type: "error", message: "Unable to create service" })
    }
  }

  const updateService = async () => {
    // if (!valid_runsheet()) return notify({ type: "error", message: "Please fill in all the runsheet details" })
    if (!service_id) return notify({ type: "error", message: "Unable to update service" })
    if (service_id === "new") return notify({ type: "error", message: "Unable to update service" })
    let endpoint = config.endpoint + "/services/id/" + service_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...serviceFormData,
        runsheet: runsheetData,
        documents: documents
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchServiceById()
      notify({ type: "success", message: "Successfully updated service" })
    } else {
      notify({ type: "error", message: "Unable to update service" })
    }
  }

  const deleteService = async () => {
    if (!service_id) return notify({ type: "error", message: "Unable to delete service" })
    if (service_id === "new") return notify({ type: "error", message: "Unable to delete service" })
    let endpoint = config.endpoint + "/services/id/" + service_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/admin/services")
    } else {
      notify({ type: "error", message: "Unable to delete service" })
    }
  }

  const createSeries = async () => {
    let endpoint = config.endpoint + "/sermon/series"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        title: seriesFormData.title,
        description: seriesFormData.description
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchSermonSeries()
      setShowSeriesModal(false)
      notify({ type: "success", message: "Successfully created new series" })
    } else {
      notify({ type: "error", message: "Unable to create new series" })
    }
  }

  // const valid_runsheet = () => {
  //   let valid = true
  //   runsheetData.map((item, index) => {
  //     if (!item.runsheet_item || !item.start_time || !item.duration) {
  //       valid = false
  //     }
  //   })
  //   // check if the start time and duration are in the correct format
  //   runsheetData.map((item, index) => {
  //     let newStartTime = new Date("1970-01-01T" + item.start_time + "")
  //     let newEndTime = new Date("1970-01-01T" + item.start_time + "")
  //     newStartTime.setMinutes(newStartTime.getMinutes() + parseInt(item.duration))
  //     if (newStartTime < newEndTime) {
  //       valid = false
  //     }
  //   })
  //   return valid
  // }

  // useEffect(() => {
  //   // Check the runsheet time for any changes and make sure the date and time are in the correct format
  //   let newRunsheet = [...runsheetData]
  //   newRunsheet.map((item, index) => {
  //     let newStartTime = new Date("1970-01-01T" + item.start_time + "")
  //     newStartTime = newStartTime.toTimeString().slice(0, 5)
  //     newRunsheet[index].start_time = newStartTime
  //     return item
  //   })
  //   setRunsheetData(newRunsheet)
  // } , [runsheetData])


  return (
    <AdminLayout
      navSelected={"Services"}
      regularContainer={true}
    >

      <ModalLayout modalTitle="Create Series" isVisible={showSeriesModal} setIsVisible={setShowSeriesModal}>
        <div className="input-container">
          <p className="input-label">Title</p>
          <input type="text" className="text-input" value={seriesFormData.title} onChange={(e) => setSeriesFormData({ ...seriesFormData, title: e.target.value })} />
        </div>
        <div className="input-container">
          <p className="input-label">Description</p>
          <textarea type="text" className="textarea-input" value={seriesFormData.description} onChange={(e) => setSeriesFormData({ ...seriesFormData, description: e.target.value })} />
        </div>
        <div className="flex-row flex justifyEnd">
          <button className="button" onClick={createSeries}>Create</button>
        </div>
      </ModalLayout>

      <ModalLayout modalTitle={`Delete Service: ${serviceFormData?.service_title}`} 
      isVisible={showDeleteConfirmationModal} setIsVisible={setShowDeleteConfirmationModal}>
        <div className="input-container">
          <p className="input-label">To confirm deletion, please type 'delete'</p>
          <input type="text" className="text-input" value={deleteConfirmation} onChange={(e) => setDeleteConfirmation(e.target.value)} />
        </div>  
        <div className="flex-row flex justifyEnd">  
          <button className="button-delete" onClick={()=>{
            if (deleteConfirmation === "delete") {
              deleteService()
            } else {
              notify({ type: "error", message: "Please type 'delete' to confirm deletion" })
            }
          
          }}>Delete</button>
        </div>  
      </ModalLayout>



      {/* <pre>{JSON.stringify(serviceFormData, null, 2)}</pre>
      <pre>{JSON.stringify(sermonSeries, null, 2)}</pre> */}
      <div className="flex-row flex gap-2 alignStart">
        {/* Left */}
        <div className="w-70">
          <div className="card">
            <p className="card-title">Service Information</p>
            {service_id != "new" && service_id && <div className="w-30 mt-4">
              <FileUploadField
                text={"Upload Service Header"}
                upload_url={config.endpoint + "/file/services/id/" + service_id}
                onChange={() => { }}
                subtext={"Upload a header image for the service"}
                item_index={0}
              />
            </div>}
            <div className="flex-row flex gap-2 items-center">
              <div className="input-container">
                <p className="input-label">Title</p>
                <input type="text" className="text-input" value={serviceFormData.service_title} onChange={(e) => setServiceFormData({ ...serviceFormData, service_title: e.target.value })} placeholder='e.g. Sunday Service' />
              </div>
              {/* <div className="input-container">
                <p className="input-label">Service Profile</p>
                <Select
                  options={serviceTypeData.map((type, index) => {
                    return { value: type.id, label: type.name }
                  })}
                  value={serviceTypeData?.map((type, index) => {
                    return { value: type.id, label: type.name }
                  }).find(type => type.value == serviceFormData.service_type_id)}
                  onChange={(e) => setServiceFormData({ ...serviceFormData, service_type_id: e.value })}
                />
              </div> */}
              {/* <p>{JSON.stringify(serviceTypeData?.find(type => type.id == serviceFormData.service_type_id))}</p> */}
            </div>
            <div className="flex-row flex gap-2 items-center">
              <div className="input-container">
                <p className="input-label">From</p>
                <input type="datetime-local" className="text-input" value={serviceFormData.service_start_datetime} onChange={(e) => setServiceFormData({ ...serviceFormData, service_start_datetime: e.target.value })} />
              </div>
              <div className="input-container">
                <p className="input-label">To</p>
                <input type="datetime-local" className="text-input" value={serviceFormData.service_end_datetime} onChange={(e) => setServiceFormData({ ...serviceFormData, service_end_datetime: e.target.value })} />
              </div>
              <div className="flex-row items-center flex gap-2">
                <Switch
                  onChange={(checked) => setServiceFormData({ ...serviceFormData, is_published: checked ? "1" : "0" })}
                  checked={serviceFormData.is_published == "1"}
                />
                <p className='small' style={{ whiteSpace: "nowrap" }}>Published</p>
              </div>
              <div className="flex-row items-center flex gap-2">
                <Switch
                  onChange={(checked) => setServiceFormData({ ...serviceFormData, is_special_service: checked ? "1" : "0" })}
                  checked={serviceFormData.is_special_service == "1"}
                />
                <p className='small' style={{ whiteSpace: "nowrap" }}>Special Service</p>
              </div>
            </div>
          </div>


          {/* Special Service */}
          {serviceFormData?.is_special_service == "1" && <div className="card mt-10">
            <p className="card-title">Special Service</p>
            <div className="input-container column left alignStart">
              <p className="input-label">Series</p>
              <Select
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    width: "100%"
                  })
                }}
                options={specialServicesData.map((special_service, index) => {
                  return { value: special_service.id, label: special_service.name }
                })}
                value={specialServicesData?.map((special_service, index) => {
                  return { value: special_service.id, label: special_service.name }
                }).find(special_service => special_service.value == serviceFormData?.special_service_id)}
                onChange={(e) => setServiceFormData({ ...serviceFormData, special_service_id: e.value })}
              />
            </div>
          </div>}

          <div className="card mt-10">
            <p className="card-title">Sermon</p>
            <div className="flex-row flex gap-2 alignStart">
              <div className="input-container">
                <p className="input-label">Title</p>
                <input type="text" className="text-input" value={serviceFormData.sermon_title} onChange={(e) => setServiceFormData({ ...serviceFormData, sermon_title: e.target.value })} />
              </div>
              <div className="input-container column left alignStart">
                <p className="input-label">Series</p>
                <Select
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%"
                    })
                  }}
                  options={sermonSeries.map((series, index) => {
                    return { value: series.id, label: series.title }
                  })}
                  value={sermonSeries?.map((series, index) => {
                    return { value: series.id, label: series.title }
                  }).find(series => series.value == serviceFormData.sermon_series_id)}
                  onChange={(e) => setServiceFormData({ ...serviceFormData, sermon_series_id: e.value })}
                />
                <button className="small backgroundLess"
                  onClick={() => {
                    setShowSeriesModal(true)
                  }}
                >Create Series</button>
              </div>
            </div>
            <JoditEditor
              ref={editor}
              value={serviceFormData.sermon_outline}
              config={liveConfig}
              tabIndex={1} // tabIndex of textarea
              onBlur={newContent => {
                setServiceFormData({ ...serviceFormData, sermon_outline: newContent })
              }}

            />
          </div>

          <div className="card mt-10">
            <p className="card-title">Runsheet</p>
            <div className="flex-row flex justify-between items-center">
              <p className="input-label">Items</p>
              <button className="backgroundLess row gap-2 items-center"
                onClick={() => {
                  let newRunsheet = [...runsheetData]
                  let previousTime = newRunsheet[newRunsheet.length - 1].start_time
                  let previousDuration = newRunsheet[newRunsheet.length - 1].duration
                  // add both the previous time and duration to get the next start time
                  //if (!previousTime || !previousDuration) return notify({ type: "error", message: "Please fill in the previous time and duration" })

                  let nextStartTime = new Date("1970-01-01T" + previousTime + "")
                  nextStartTime.setMinutes(nextStartTime.getMinutes() + parseInt(previousDuration))
                  nextStartTime = nextStartTime.toTimeString().slice(0, 5)

                  newRunsheet.push({
                    runsheet_item: "",
                    start_time: nextStartTime,
                    duration: "",
                    internal_notes: "",
                    description: "",
                    item_code: "regular",
                    is_public: "1"
                  })
                  setActiveRunSheet(newRunsheet.length - 1)
                  setRunsheetData(newRunsheet)
                }}
              >
                <MdAdd size={15} />
                <p>Add</p>
              </button>
            </div>
            {runsheetData.map((item, index) => {
              return (
                <div key={index} className="mt-10 pb-2" style={{ minWidth: 0, borderBottom: index === runsheetData.length - 1 ? "none" : "2px solid #ddd" }}>
                  {(showAll || activeRunSheet === index) ? <>
                    <div className="flex-row flex gap-2 items-center">
                      <div className="input-container" style={{ minWidth: 0 }}>
                        <label htmlFor="" className="input-label">Item</label>
                        <input type="text" className="text-input" value={item.runsheet_item} style={{ minWidth: 0 }} onChange={(e) => {
                          let newRunsheet = [...runsheetData]
                          newRunsheet[index].runsheet_item = e.target.value
                          setRunsheetData(newRunsheet)
                        }} placeholder="Item" />
                      </div>
                      <div className="input-container" style={{ minWidth: 0 }}>
                        <label htmlFor="" className="input-label">Personnel</label>
                        <input type="text" className="text-input" value={item.personnel_name} style={{ minWidth: 0 }} onChange={(e) => {
                          let newRunsheet = [...runsheetData]
                          newRunsheet[index].personnel_name = e.target.value
                          setRunsheetData(newRunsheet)
                        }} placeholder="e.g. John Tan" />
                      </div>
                      <div className="input-container" style={{ minWidth: 0 }}>
                        <p className="input-label">Service Type</p>
                        <Select
                          options={runsheet_items.map((item, index) => {
                            return { value: item, label: capitaliseFirstLetter(item) }
                          })}
                          value={runsheet_items?.map((run_item, index) => {
                            return { value: run_item, label: capitaliseFirstLetter(run_item) }
                          }).find(find_item => find_item.value.toLowerCase() == item.item_code?.toLowerCase())}
                          onChange={(e) => {
                            let newRunsheet = [...runsheetData]
                            newRunsheet[index].item_code = e.value
                            setRunsheetData(newRunsheet)
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="flex-row flex gap-2 items-center justify-between" style={{ minWidth: 0 }}>
                      <div className="input-container" style={{ minWidth: 0 }}>
                        <label htmlFor="" className="input-label">Start Time</label>
                        <input type="time" className="text-input" value={item.start_time} style={{ minWidth: 0 }} onChange={(e) => {
                          let newRunsheet = [...runsheetData]
                          newRunsheet[index].start_time = e.target.value
                          setRunsheetData(newRunsheet)
                        }} placeholder="Start Time" />
                      </div>
                      <div className="input-container" style={{ minWidth: 0 }}>
                        <label htmlFor="" className="input-label">Duration</label>
                        <input type="number" className="text-input" value={item.duration} style={{ minWidth: 0 }} onChange={(e) => {
                          let newRunsheet = [...runsheetData]
                          newRunsheet[index].duration = e.target.value
                          setRunsheetData(newRunsheet)
                        }} placeholder="Duration" />
                      </div>
                    </div> */}
                    <div className="input-container" style={{ minWidth: 0 }}>
                      <label htmlFor="" className="input-label">Internal Notes</label>
                      <textarea type="text" className="textarea-input" value={item.internal_notes} style={{ minWidth: 0 }} onChange={(e) => {
                        let newRunsheet = [...runsheetData]
                        newRunsheet[index].internal_notes = e.target.value
                        setRunsheetData(newRunsheet)
                      }} placeholder="Internal Notes" />
                    </div>
                    <div className="input-container" style={{ minWidth: 0 }}>
                      <label htmlFor="" className="input-label">Description</label>
                      <textarea type="text" className="textarea-input" value={item.description} style={{ minWidth: 0 }} onChange={(e) => {
                        let newRunsheet = [...runsheetData]
                        newRunsheet[index].description = e.target.value
                        setRunsheetData(newRunsheet)
                      }} placeholder="Description" />
                    </div>

                    <div className="column right alignEnd">
                      <button className="backgroundLess button-delete flex-row items-center flex gap-2"
                        onClick={() => {
                          let newRunsheet = [...runsheetData]
                          newRunsheet.splice(index, 1)
                          setRunsheetData(newRunsheet)
                          setActiveRunSheet(newRunsheet.length - 1)
                        }}
                      >
                        <MdDelete size={15} />
                        <p>Delete</p>
                      </button>
                    </div>
                  </>
                    :
                    <div className="flex-row flex gap-2 alignCente justify-between">
                      <div>
                        <p>{item?.runsheet_item}</p>
                        {/* <p className='card-subtitle'>{item.start_time} ({item.duration} mins)</p> */}
                        <p className='card-subtitle'>{item?.personnel_name}</p>
                      </div>
                      <div className="flex-row flex gap-2 items-center">
                        <button className="backgroundLess" onClick={() => setActiveRunSheet(index)}>
                          <MdEdit size={15} />
                        </button>
                        <button className="backgroundLess" onClick={() => {
                          let newRunsheet = [...runsheetData]
                          let part_1 = newRunsheet.slice(0, index + 1)
                          let part_2 = newRunsheet.slice(index + 1, newRunsheet.length)
                          console.log({ part_1, part_2 })
                          let temp = [{
                            runsheet_item: "",
                            start_time: "",
                            duration: "",
                            internal_notes: "",
                            description: "",
                            item_code: "regular",
                            is_public: "1"
                          }]
                          let newRunsheetData = [
                            ...part_1,
                            ...temp,
                            ...part_2
                          ]
                          setRunsheetData(newRunsheetData)
                          setActiveRunSheet(part_1.length)
                        }}>
                          <MdAdd size={15} />
                        </button>
                      </div>
                    </div>
                  }
                </div>
              )
            })}
          </div>
        </div>

        {/* Right */}
        <div className="w-30">
          <div className="card">
            <p className="card-title mb-2">Services</p>
            {serviceSettings?.length > 0 && (
              serviceSettings.map((service, index) => {
                return (
                  <div className="flex-row flex gap-2 items-center mb-2">
                    <div className="flex-row flex gap-2 items-center">
                      <Switch
                        checked={serviceFormData?.service_type_ids?.includes(service.id)}
                        onChange={(e) => {
                          let newServiceTypes = serviceFormData.service_type_ids
                          if (!newServiceTypes) {
                            newServiceTypes = []
                          }
                          if (e) {
                            newServiceTypes.push(service.id)
                          } else {
                            newServiceTypes = newServiceTypes.filter(item => item !== service.id)
                          }
                          setServiceFormData({ ...serviceFormData, service_type_ids: newServiceTypes })
                        }}
                      />
                      <p className="input-label">{service.name}</p>
                    </div>
                  </div>
                )
              })
            )}
          </div>
          <div className="card mt-10">
            <p className="card-title">Media & Documents</p>
            <div className="input-container mt-30">
              <FileUploadField
                text="Sermon Notes"
                upload_url={config.endpoint + "/file/sermon/notes/" + service_id}
                max_file_size={5}
                max_files={5}
                onChange={(files) => {
                  fetchServiceById()
                 }}
                item_index={Math.random()}
              />

              {/* TODO Show the uploaded materials here */}
              {documents?.length > 0 && documents.map((file, index) => {
                if (file?.is_deleted == 1) return null
                return (
                  <div key={index} className='mb-4'>
                    <p className="input-label mb-5">Document {index+1}</p>
                    <div className="flex-row flex gap-2 items-center mt-10 justify-between">
                      <input
                        type="text"
                        className="text-input"
                        value={file?.file_data?.file_name}
                        onChange={(e) => {
                          let newDocuments = [...documents]
                          newDocuments[index].file_data.file_name = e.target.value
                          setDocuments(newDocuments)
                        }}
                        placeholder="Document name"
                      />
                      <div className="flex-row flex gap-2 items-center">
                        <button className="backgroundLess" onClick={() => {
                          window.open(`${config.endpoint}${file.file_data.ext_file_path}`, "_blank")
                        }}>
                          <MdOpenInBrowser size={15} />
                        </button>
                        <button className="backgroundLess" onClick={() => {
                          let newDocuments = [...documents]
                          newDocuments[index].is_deleted = 1
                          setDocuments(newDocuments)
                        }}>
                          <MdDelete size={15} />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="input-container">
              <p className="input-label">Video Link</p>
              <input type="text" className="text-input" placeholder='e.g. https://www.youtube.com/embed' value={serviceFormData.video_link} onChange={(e) => setServiceFormData({ ...serviceFormData, video_link: e.target.value })} />
            </div>
            <div className="input-container">
              <p className="input-label">Worship Link</p>
              <input type="text" className="text-input" placeholder='e.g. https://www.youtube.com/embed' value={serviceFormData.worship_link} onChange={(e) => setServiceFormData({ ...serviceFormData, worship_link: e.target.value })} />
            </div>
            <div className="input-container">
              <p className="input-label">Sermon Link</p>
              <input type="text" className="text-input" placeholder='e.g. https://www.youtube.com/embed' value={serviceFormData.sermon_link} onChange={(e) => setServiceFormData({ ...serviceFormData, sermon_link: e.target.value })} />
            </div>
            <div className="input-container">
              <p className="input-label">Bulletin</p>
              <Select
                options={bulletin.map((bulletin, index) => {
                  return { value: bulletin.id, label: bulletin.title }
                })}
                value={bulletin.map((bulletin, index) => {
                  return { value: bulletin.id, label: bulletin.title }
                }).find(bulletin => bulletin.value == serviceFormData.bulletin_id)}
                onChange={(e) => setServiceFormData({ ...serviceFormData, bulletin_id: e.value })}
              />
            </div>
          </div>

          <div className="card mt-10">
            <p className="card-title">Worship Set</p>
            <div className="input-container">
              <p className="input-label">Set list</p>
              <textarea type="text" className="textarea-input" placeholder='e.g. Here I Bow' value={serviceFormData.worship_songs} onChange={(e) => setServiceFormData({ ...serviceFormData, worship_songs: e.target.value })} />
            </div>
          </div>

          <div className="card mt-10">
            <div className="flex-row flex justify-between items-center">
              <p className="card-title">Servers</p>
              <button className="backgroundLess row gap-2 items-center"
                onClick={() => {
                  let newServers = [...serviceFormData.servers]
                  newServers.push({ name: "", role: "" })
                  setServiceFormData({ ...serviceFormData, servers: newServers })
                }}
              >
                <MdAdd size={15} />
                <p>Add</p>
              </button>
            </div>
            {/* Find the distinct roles, filter and iterate by filtered roles */}
            {/* {serviceFormData.servers.map((server, index) => {
              return (
                <div key={index} className="flex-row flex gap-2 items-center mt-10" style={{ minWidth: 0 }}>
                  <div className="input-container" style={{ minWidth: 0 }}>
                    <label htmlFor="" className="input-label">Name</label>
                    <input type="text" className="text-input" value={server.name} style={{ minWidth: 0 }} onChange={(e) => {
                      let newServers = [...serviceFormData.servers]
                      newServers[index].name = e.target.value
                      setServiceFormData({ ...serviceFormData, servers: newServers })
                    }} placeholder="Name" />
                  </div>
                  <div className="input-container" style={{ minWidth: 0 }}>
                    <label htmlFor="" className="input-label">Role</label>
                    <input type="text" className="text-input" value={server.role} style={{ minWidth: 0 }} onChange={(e) => {
                      let newServers = [...serviceFormData.servers]
                      newServers[index].role = e.target.value
                      setServiceFormData({ ...serviceFormData, servers: newServers })
                    }} placeholder="Role" />
                  </div>
                </div>
              )
            })} */}
            {serviceFormData.servers.map((server, index) => {
              return (
                <div key={index} className="mt-10" style={{ minWidth: 0, borderBottom: index === serviceFormData.servers.length - 1 ? "none" : "2px solid #ddd" }}>
                  <div className="input-container" style={{ minWidth: 0 }}>
                    <label htmlFor="" className="input-label">Role</label>
                    <input type="text" className="text-input" value={server.role} style={{ minWidth: 0 }} onChange={(e) => {
                      let newServers = [...serviceFormData.servers]
                      newServers[index].role = e.target.value
                      setServiceFormData({ ...serviceFormData, servers: newServers })
                    }} placeholder="Role" />
                  </div>
                  <div className="input-container" style={{ minWidth: 0 }}>
                    <label htmlFor="" className="input-label">Name</label>
                    <textarea type="text" className="textarea-input" value={server.name} style={{ minWidth: 0 }} onChange={(e) => {
                      let newServers = [...serviceFormData.servers]
                      newServers[index].name = e.target.value
                      setServiceFormData({ ...serviceFormData, servers: newServers })
                    }} placeholder="Name" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {/* Button Group */}
      <div className="column right alignEnd w-100 mt-10">
        <div className="flex-row items-center flex gap-2">
          {service_id === "new" ? (
            <button className="button" onClick={createService}>Create</button>
          ) : (
            <>
              <button className="button-delete" onClick={()=>setShowDeleteConfirmationModal(true)}>Delete</button>
              <button className="button" onClick={updateService}>Update</button>
            </>
          )}
        </div>
      </div>
    </AdminLayout >
  )
}
