import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import { MdAdd, MdList, MdPersonAdd, MdTableView } from 'react-icons/md';
import { capitaliseFirstLetter, tidyDate, tidyDateRaw } from '../../utils/helper';
import Table from '../../components/app-components/Table';

export default function MembersPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")
  const [dataView, setDataView] = useState("list") // list or table

  useEffect(() => {
    fetchPlatformUsers()
  }, [])

  const fetchPlatformUsers = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/users/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setUsers(data.data)
      console.log(data.data)
    }
    setLoading(false)
  }


  let table_columns = [
    {
      header: "Name",
      data: (row) => `${row?.first_name} ${row?.last_name}`
    },
    {
      header: "Email",
      data: (row) => row?.email
    },
    {
      header: "Service",
      data: (row) => `${row?.service_type?.name || "N/A"}`
    },
    {
      header: "Role",
      data: (row) => `${capitaliseFirstLetter(row?.account_type)}`
    },

  ]

  return (
    <AdminLayout
      navSelected={"Members"}
      toolbar={
        <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Members</h1>
          <button
            className="button flex-row items-center flex gap-2"
            onClick={() => {
              navigate("/admin/users/details?user_id=new")
            }}
          >
            <MdAdd size={15} />
            User
          </button>
        </div>
      }
    >

      <div className="column items-center justify-between w-100 mb-4">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
        <div className="flex flex-col items-end w-full p-2">
          <div className="flex flex-row items-center bg-white rounded-md">
            <button className={`${dataView === "list" ? "bg-[var(--primary)]" : ""} rounded-md p-2`}>
              <MdList size={30} onClick={() => setDataView("list")} className={`${dataView === "list" ? "fill-white" : "fill-[var(--primary)]"} rounded-md p-2 text-sm`} />
            </button>
            <button className={`${dataView === "table" ? "bg-[var(--primary)]" : ""} rounded-md p-2`}>
              <MdTableView size={30} onClick={() => setDataView("table")} className={`${dataView === "table" ? "fill-white" : "fill-[var(--primary)]"} rounded-md p-2 text-sm`} />
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex-row items-center flex justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <>
          {dataView === "list" && <div className="w-100">
            {users.map((user, index) => {
              if (!JSON.stringify(user).toLowerCase().includes(query.toLowerCase())) return null
              return (
                <div
                  key={index}
                  className="flex-row items-center flex justify-between w-100 card mt-10"
                >
                  <div className="">
                    <h3>{user.first_name} {user.last_name}</h3>
                    <h3 className='card-subtitle'>{user?.service_type?.name}</h3>
                  </div>
                  <button
                    className="button"
                    onClick={() => {
                      navigate("/admin/users/details?user_id=" + user.id)
                    }}
                  >
                    View
                  </button>
                </div>
              )
            })}
          </div>}
          {dataView === "table" && <Table
            columns={table_columns}
            data={users?.map((user) => {
              if (query && !JSON.stringify(user).toLowerCase().includes(query.toLowerCase())) return {}
              return user
            })?.filter((user) => Object.keys(user).length > 0)}
            actions={(row) => {
              return (
                <>
                  <div className="flex flex-row items-center gap-2">
                    <button className="text-xs hover:underline" onClick={() => {
                      navigate("/admin/users/details?user_id=" + row.id, { replace: false })
                    }}>View</button>
                  </div>
                </>
              )
            }}
          />}
        </>
      )
      }
    </AdminLayout >
  )
}
