import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import JoditEditor from 'jodit-react';
import Switch from "react-switch";
import FileUploadField from '../../components/FileUploadField';
import { capitaliseFirstLetter } from '../../utils/helper';

export default function AnnouncementFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  let announcement_id = new URLSearchParams(window.location.search).get("announcement_id")

  const [announcementFormData, setAnnouncementFormData] = useState({
    title: "",
    description: "",
    start_datetime: "",
    end_datetime: "",
    is_forever: 0,
    service_type_id: [],
    link_id: "",
    channels: ["bulletin", "announcement"],
    link_name: "",
    link_url: "",
    is_member_news: 0,
    is_popup: 0,
    persistent: 0,
    popup_summary: "",
  })

  const [linkData, setLinkData] = useState([])
  const [linkFormData, setLinkFormData] = useState({
    link_name: "",
    link_url: "",
    link_type: "",
    is_public: 1,
  })


  const editor = useRef(null);
  const placeholder = "Start typing...";
  const runsheet_items = ["regular", "worship", "sermon", "tithe"]
  const channels_types = ["bulletin", "announcement"]

  const [serviceSettings, setServiceSettings] = useState([])
  const liveConfig = {
    height: 500,
    placeholder,
    // set custom buttons
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'superscript',
      'subscript',
      'ul',
      'ol',
      'outdent',
      'indent',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      'table',
      'link',
      'undo',
      'redo',
      'eraser',
      'copyformat',
    ],
    askBeforePasteHTML: false,
    hotkeys: {
      redo: 'ctrl+z',
      undo: 'ctrl+y,ctrl+shift+z',
      indent: 'ctrl+]',
      outdent: 'ctrl+[',
      bold: 'ctrl+b',
      italic: 'ctrl+i',
      removeFormat: 'ctrl+shift+m',
      insertOrderedList: 'ctrl+shift+7',
      insertUnorderedList: 'ctrl+shift+8',
      openSearchDialog: 'ctrl+f',
      openReplaceDialog: 'ctrl+r',
    },
  };

  useEffect(() => {
    fetchAnnouncementById()
  }, [])

  const fetchAnnouncementById = async () => {
    if (announcement_id && announcement_id !== "new") {
      let endpoint = config.endpoint + "/announcements/id/" + announcement_id
      let response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      })
      let data = await response.json()
      if (data.status === 200) {
        setAnnouncementFormData(data.data)
      } else {
        notify({ type: "error", message: "Failed to fetch announcement data" })
      }
    }
  }

  const createAnnouncement = async () => {
    // if (linkFormData?.title && !announcementFormData?.link_id) return notify({ type: "error", message: "Please save the link before creating the announcement" })
    let endpoint = config.endpoint + "/announcements"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(announcementFormData),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Announcement created successfully" })
      navigate("/admin/announcements/details?announcement_id=" + data.data.id)
    } else {
      notify({ type: "error", message: "Failed to create announcement" })
    }
  }

  const updateAnnouncement = async () => {
    if (!announcement_id || announcement_id === "new") return
    let endpoint = config.endpoint + "/announcements/id/" + announcement_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(announcementFormData),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Announcement updated successfully" })
    } else {
      notify({ type: "error", message: "Failed to update announcement" })
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if (announcement_id && announcement_id !== "new") {
      updateAnnouncement()
    } else {
      createAnnouncement()
    }
  }

  const deleteAnnouncement = async () => {
    if (!announcement_id || announcement_id === "new") return
    let endpoint = config.endpoint + "/announcements/id/" + announcement_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Announcement deleted successfully" })
      navigate("/admin/announcements")
    } else {
      notify({ type: "error", message: "Failed to delete announcement" })
    }
  }


  const fetchLinkById = async () => {
    if (!announcementFormData?.link_id) return
    let endpoint = config.endpoint + "/config/links/id/" + announcementFormData?.link_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })

    let data = await fetchResponse.json()
    if (data.status === 200) {
      setLinkFormData(data.data)
    } else {
      notify({ message: "An error occurred", type: "error" })
    }
  }

  const fetchConfigSettings = async () => {
    let endpoint = config.endpoint + "/config"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })

    let data = await fetchResponse.json()
    if (data.status === 200) {
      setServiceSettings(data.data.services)
    } else {
      notify({ type: "error", message: "Failed to fetch config settings" })
    }
  }

  // const submitLink = async () => {
  //   let endpoint = config.endpoint + "/config/links"
  //   let response = await fetch(endpoint, {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     credentials: "include",
  //     body: JSON.stringify({
  //       id: announcementFormData.link_id,
  //       ...linkFormData,
  //     }),
  //   })
  //   let data = await response.json()
  //   if (data.status === 200) {
  //     notify({ type: "success", message: "Link created successfully" })
  //     setAnnouncementFormData({ ...announcementFormData, link_id: data.data.id })
  //   } else {
  //     notify({ type: "error", message: "Failed to create link" })
  //   }
  // }

  // const fetchAnnouncementsById = async () => {
  //   if (announcement_id && announcement_id !== "new") {
  //     let endpoint = config.endpoint + "/announcements/id/" + announcement_id
  //     let response = await fetch(endpoint, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       credentials: "include",
  //     })
  //     let data = await response.json()
  //     if (data.status === 200) {
  //       setAnnouncementFormData(data.data)
  //       fetchConfigSettings()
  //     } else {
  //       notify({ type: "error", message: "Failed to fetch announcement data" })
  //     }
  //   } else {

  //   }
  // }

  useEffect(() => {
    // fetchAnnouncementsById()
    fetchAnnouncementById()
    fetchConfigSettings()
    // fetchAnnouncementsById()
  }, [])

  useEffect(() => {
    fetchLinkById()
  }, [announcementFormData])


  return (
    <AdminLayout
      navSelected={"Announcements"}
    >
      <div className="card mt-10">
        <p className="card-title">Announcement</p>
        {/* <pre>{JSON.stringify(linkData,null,2)}</pre> */}
        {announcementFormData?.file_data?.ext_file_path && <img src={config.endpoint + announcementFormData?.file_data?.ext_file_path} alt="Announcement" style={{ width: "500px", marginBlock: 20, height: "auto", objectFit: "cover", borderRadius: "10px" }} />}
        {announcement_id && announcement_id !== "new" && (
          <FileUploadField
            upload_url={config.endpoint + "/file/announcements/" + announcement_id}
            max_files={1}
            max_file_size={1}
            onChange={(files) => {
              fetchAnnouncementById()
            }}
            text={"Upload Image"}
          />
        )}
        <div className="flex-row flex gap-2 alignStart">
          <div className="input-container">
            <p className="input-label">Title</p>
            <input type="text" className="text-input" value={announcementFormData.title} onChange={(e) => setAnnouncementFormData({ ...announcementFormData, title: e.target.value })} />
          </div>
        </div>
        <div className="flex-row flex gap-2 items-center">
          <div className="input-container">
            <p className="input-label">Start Date</p>
            <input type="date" className="text-input" value={announcementFormData.start_datetime?.split("T")[0]} onChange={(e) => setAnnouncementFormData({ ...announcementFormData, start_datetime: e.target.value })} />
          </div>
          <div className="input-container">
            <p className="input-label">End Date</p>
            <input type="date" className="text-input" value={announcementFormData.end_datetime?.split("T")[0]} onChange={(e) => setAnnouncementFormData({ ...announcementFormData, end_datetime: e.target.value })} />
          </div>
          <div className="flex-row flex gap-2 items-center">
            <Switch
              checked={announcementFormData.is_forever == 1}
              onChange={(e) => setAnnouncementFormData({ ...announcementFormData, is_forever: e ? 1 : 0 })}
            />
            <p className="small" style={{ whiteSpace: "nowrap" }}>No End Date</p>
          </div>
        </div>
        <div className="flex-row flex gap-2 alignStart mt-30 mb-2">
          <div className='w-50'>
            <p className="card-title">Audience</p>

            <div className="flex-row flex gap-2 items-center mt-4 mb-30">
              <Switch
                checked={announcementFormData.is_member_news == 1}
                onChange={(e) => setAnnouncementFormData({ ...announcementFormData, is_member_news: e ? 1 : 0 })}
              />
              <p className="input-label">Member News</p>
            </div>

            {serviceSettings?.length > 0 && (
              serviceSettings.map((service, index) => {
                return (
                  <div className="flex-row flex gap-2 items-center mb-2">
                    <div className="flex-row flex gap-2 items-center">
                      <Switch
                        checked={announcementFormData?.service_type_id?.includes(service.id)}
                        onChange={(e) => {
                          let newServiceTypes = announcementFormData.service_type_id
                          if (!newServiceTypes) {
                            newServiceTypes = []
                          }
                          if (e) {
                            newServiceTypes.push(service.id)
                          } else {
                            newServiceTypes = newServiceTypes.filter(item => item !== service.id)
                          }
                          setAnnouncementFormData({ ...announcementFormData, service_type_id: newServiceTypes })
                        }}
                      />
                      <p className="input-label">{service.name}</p>
                    </div>
                  </div>
                )
              })
            )}
          </div>
          <div className='w-50'>


            <>
              <p className="card-title">Links</p>
              <div className="flex-row flex gap-2 items-center">
                <div className="input-container" style={{ minWidth: 0 }}>
                  <p className="input-label">Link Name</p>
                  <input type="text" className="text-input" value={announcementFormData.link_name} placeholder='e.g. Ms Jamie Tan' onChange={(e) => setAnnouncementFormData({ ...announcementFormData, link_name: e.target.value })} />
                </div>
              </div>
              <div className="input-container">
                <p className="input-label">Link URL</p>
                <input type="text" className="text-input" value={announcementFormData.link_url} placeholder='e.g. tel:81234567' onChange={(e) => setAnnouncementFormData({ ...announcementFormData, link_url: e.target.value })} />
              </div>

              <p className="card-title mt-4">Popup Announcements</p>
              <div className="input-container">
                <p className="input-label">Enable</p>
                <Switch
                  checked={announcementFormData?.is_popup == 1}
                  onChange={(e) => setAnnouncementFormData({ ...announcementFormData, is_popup: e ? 1 : 0 })}
                />
              </div>
              {announcementFormData?.is_popup == 1 &&
                <>
                  <div className="input-container">
                    <p className="input-label">Persistent</p>
                    <Switch
                      checked={announcementFormData?.persistent == 1}
                      onChange={(e) => setAnnouncementFormData({ ...announcementFormData, persistent: e ? 1 : 0 })}
                    />
                  </div>
                  <div className="input-container">
                    <p className="input-label">Summary</p>
                    <textarea name="" id="" className="textarea-input" value={announcementFormData?.popup_summary}
                      onChange={(e) => {
                        if (e.target.value.length > 120) return
                        setAnnouncementFormData({ ...announcementFormData, popup_summary: e.target.value })
                      }}
                    ></textarea>
                    <p className={`text-xs text-right ${announcementFormData?.popup_summary?.length >= 120 ? "text-red-400" : "text-gray-300"}`}>{announcementFormData?.popup_summary?.length || 0}/120</p>
                  </div>
                </>
              }
            </>


            {/* Disabled Channels */}
            {/* <>
            <p className="card-title mt-4">Channels</p>
            {channels_types.map((channel, index) => {
              return (
                <div className="mt-10">
                  <div className="flex-row flex gap-2 items-center">
                    <Switch
                      checked={announcementFormData?.channels?.includes(channel)}
                      onChange={(e) => {
                        let newChannels = announcementFormData.channels
                        if (!newChannels) {
                          newChannels = []
                        }
                        if (e) {
                          newChannels.push(channel)
                        } else {
                          newChannels = newChannels.filter(item => item !== channel)
                        }
                        setAnnouncementFormData({ ...announcementFormData, channels: newChannels })
                      }}
                    />
                    <p className="input-label">{capitaliseFirstLetter(channel)}</p>
                  </div>
                </div>
              )
            })}
            </> */}
          </div>
        </div>
        <JoditEditor
          ref={editor}
          value={announcementFormData.description}
          config={liveConfig}
          tabIndex={1} // tabIndex of textarea
          onBlur={newContent => {
            setAnnouncementFormData({ ...announcementFormData, description: newContent })
          }}
        />
        <div className="column right alignEnd mt-10">
          <div className="flex-row flex gap-2 items-center">
            {announcement_id && announcement_id !== "new" && (
              <button onClick={deleteAnnouncement} className="button-delete">Delete</button>
            )}
            <button onClick={handleFormSubmit} className="button">Save</button>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}
