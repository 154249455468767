import React, { useState, useEffect } from 'react'
import AdminNavbar from '../components/AdminNavbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from '../utils/helper'

export default function AdminLayout({ children, navSelected, regularContainer, toolbar }) {
    const navigate = useNavigate()
    const [showNavbar, setShowNavbar] = useState(true)
    const { isAuthorised, userRole } = useFetchAuthorisation("user")
    const [applicationWidth, setApplicationWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', () => {
            setApplicationWidth(window.innerWidth)
        })
    }, [])

    return (
        <div style={{ width: "100dvw", }}>
            <ToastContainer />
            <div className='row' style={{ width: "100%" }}>
                {(showNavbar|| !isMobile(applicationWidth)) && <AdminNavbar selected={navSelected} />}
                {showNavbar && isMobile(applicationWidth) && 
                    <div className={`fixed h-dvh right-0 top-0 w-[calc(100dvw-240px)]`} onClick={() => setShowNavbar(!showNavbar)}>
                        <i className="fas fa-bars"></i>
                    </div>
                }
                <div style={{ width: "100%", maxHeight: "100dvh", overflowY: "scroll", }}>
                    <UserHeader showNavbar={showNavbar} setShowNavbar={setShowNavbar}/>
                    {toolbar}
                    {!regularContainer ?
                        <div className="container">
                            <div className="inner-container">
                                {children}
                            </div>
                        </div>

                        :
                        <div className="reg-container">
                            {children}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
