import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import MainHeader from "../components/MainHeader";

const NotFound = () => {
  return (
    <div>
      <MainHeader />
      <main
        style={{
          textAlign: "center",
          padding: 20,
        }}
      >
        <Helmet>
          <title>404 Not Found - Bedok Methodist Church</title>
        </Helmet>
        <h1 className="not-found-header">Oops!</h1>
        <div style={{ marginTop: 30 }}>
          <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            404 - PAGE NOT FOUND
          </h2>
          <p
            style={{
              width: "90%",
              margin: "auto",
              marginTop: 20,
              textWrap: "balance",
            }}
          >
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </p>
          <Link
            to="."
            style={{
              marginTop: 20,
              textDecoration: "none",
              width: "fit-content",
              display: "block",
              border: "1px solid #0099ab",

              color: "#ffffff",
              backgroundColor: "#0099ab",
              paddingBlock: 10,
              paddingInline: 20,

              borderRadius: 10,

              fontWeight: "bold",

              boxShadow: "0 3px 10px 1px grey",
              marginInline: "auto",
            }}
          >
            GO TO HOMEPAGE
          </Link>
        </div>
      </main>
      {/* <SubFooter /> */}
    </div>
  );
};

export default NotFound;
