import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import FileUploadField from '../../components/FileUploadField';
import Switch from "react-switch"


export default function ResourcesFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let resource_id = new URLSearchParams(window.location.search).get("resource_id")

  const [resourceData, setResourceData] = useState({
    name: "",
    is_published: 0,
    available_from: "",
    available_to: "",
  })

  const fetchResourceById = async () => {
    if (!resource_id || resource_id === "new") return
    let endpoint = `${config.endpoint}/resources/id/${resource_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setResourceData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }


  useEffect(() => {
    fetchResourceById()
  }, [])


  const createResource = async () => {
    let endpoint = `${config.endpoint}/resources/`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...resourceData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/resources/details?resource_id=" + data.data.id)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateResource = async () => {
    if (!resource_id || resource_id === "new") return
    let endpoint = `${config.endpoint}/resources/id/${resource_id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...resourceData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      fetchResourceById()
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteResource = async () => {
    if (!resource_id || resource_id === "new") return
    let endpoint = `${config.endpoint}/resources/id/${resource_id}`
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/resources")
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  return (
    <AdminLayout
      navSelected={"Resources"}
    // regularContainer={resource_id !== "new"}
    >
      <div className="flex-row flex gap-2 alignStart">
        <div className="w-100">
          <div className="card">
            <p className="card-title">Resource</p>
            <div className="flex-row flex gap-2 items-center">
              <div className="input-container">
                <label htmlFor="" className="input-label">Name</label>
                <input type="text" className="text-input" placeholder='e.g. Methodist Message' value={resourceData.name} onChange={(e) => setResourceData({ ...resourceData, name: e.target.value })} />
              </div>
              <div className="flex-row flex gap-2 items-center">
                <label htmlFor="" className="input-label">Draft</label>
                <Switch onChange={(checked) => setResourceData({ ...resourceData, is_published: checked ? 1 : 0 })} checked={resourceData.is_published == 1} />
                <label htmlFor="" className="input-label">Published</label>
              </div>
            </div>
            <div className="flex-row flex gap-2 items-center">
              <div className="input-container">
                <label htmlFor="" className="input-label">Available From</label>
                <input type="datetime-local" className="text-input" value={resourceData.available_from} onChange={(e) => setResourceData({ ...resourceData, available_from: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Available To</label>
                <input type="datetime-local" className="text-input" value={resourceData.available_to} onChange={(e) => setResourceData({ ...resourceData, available_to: e.target.value })} />
              </div>
            </div>
            {resource_id !== "new" && <>
              <label htmlFor="" className="input-label">Upload Resource</label>
              <div className="flex-row flex gap-2 items-center">
                {(resourceData?.url || !resourceData?.media_asset_id) && <div className="input-container">
                  <label htmlFor="" className="input-label">URL</label>
                  <input type="url" className="text-input" value={resourceData.url} onChange={(e) => setResourceData({ ...resourceData, url: e.target.value })} />
                </div>}

                {!resourceData?.url && !resourceData?.media_asset_id && <p>OR</p>}

                {(resourceData?.media_asset_id || !resourceData?.url) && <div className="input-container">
                  <label htmlFor="" className="input-label">File</label>
                  <FileUploadField
                    upload_url={`${config.endpoint}/file/resources/id/${resource_id}`}
                    onChange={(files) => {
                        fetchResourceById()
                    }}
                    max_files={1}
                    text={"Upload a PDF File"}
                    subtext={"Drag and drop a file here or click to upload"}
                  />
                </div>}
              </div>
            </>}

            <div className="column alignEnd">
              <div className="flex-row flex gap-2 items-center">
                {resource_id !== "new" && <button className="button-delete"
                  onClick={() => {
                    deleteResource()
                  }}
                >Delete</button>}
                <button className="button"
                  onClick={() => {
                    if (resource_id === "new") {
                      createResource()
                    } else {
                      updateResource()
                    }
                  }}
                >Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}
