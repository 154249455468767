import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  useFetchAuthorisation,
  authorisationConverter,
  useFetchUserData,
} from "../../utils/AppFunctions";
import config from "../../app-config";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "../../layouts/AdminLayout";
import { MdAdd } from "react-icons/md";

export default function AudioFormPage() {
  const navigate = useNavigate();
  const { isAuthorised, userRole } = useFetchAuthorisation("user");
  let user_data = useFetchUserData();

  const [audioData, setAudioData] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")
  useEffect(() => {
    fetchAudioData()
  }, [])

  const fetchAudioData = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/audio/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setAudioData(data.data)
    }
    setLoading(false)
  }



  return (
    <AdminLayout navSelected={"Audio & Worship"}
      toolbar={
        <div className='card flex-row items-center flex justify-between w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Audio & Worship</h1>
          <button
            className="button flex-row items-center flex gap-2"
            onClick={() => {
              navigate("/admin/audio/details?audio_id=new")
            }}
          >
            <MdAdd size={15} />
            Audio
          </button>
        </div>
      }
    >
      <div className="column items-center justify-between w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>
      {loading ? (
        <div className="flex-row items-center flex justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {audioData.map((data, index) => {
            if (!data.title.toLowerCase().includes(query.toLowerCase())) return null
            return (
              <div
                key={index}
                className="flex-row items-center flex justify-between w-100 card mt-10"
              >
                <div className="">
                  <h3>{data.title}</h3>
                  <h3 className='gray card-subtitle'>{data?.remarks}</h3>
                </div>
                <button
                  className="backgroundLess"
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    navigate("/admin/audio/details?audio_id=" + data.id)
                  }}
                >
                  View
                </button>
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  );
}