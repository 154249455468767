import React, { useEffect, useState } from 'react'
import config from '../app-config'
import notify from '../utils/IANotification'
import { IoCloudUpload } from 'react-icons/io5'
import { MdUploadFile } from 'react-icons/md'


export default function FileUploadField({ item_index, max_file_size, max_files, onChange, upload_url, text, subtext }) {
    const [filesToUpload, setFilesToUpload] = useState([])


    const submitDocuments = async () => {
        let formData = new FormData()
        for (let i = 0; i < filesToUpload.length; i++) {
            formData.append("files", filesToUpload[i])
        }
        let endpoint = upload_url
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            body: formData,
            credentials: "include"
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            if (onChange) onChange(data?.data?.files)
            notify({ message: "Files uploaded successfully", type: "success" })
            setFilesToUpload([])
        } else {
            // error
            notify({ message: data.message, type: "error" })
        }
    }

    useEffect(() => {
        let fileInput = document?.getElementById(`file-input-${item_index}`)
        let fileInputContainer = document?.getElementById(`file-input-container-${item_index}`)
        if (!fileInput || !fileInputContainer) return
        fileInput.addEventListener("dragover", (e) => {
            // add a class hover
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.add("hover")
        })
        fileInput.addEventListener("dragleave", (e) => {
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.remove("hover")
        })

        fileInput.addEventListener("drop", (e) => {
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.remove("hover")
            // get the files
            let files = e.dataTransfer.files
            // convert to array
            files = [...files]
            // check if the file is valid
            if (files.length > 0) {
                // set the file input to the files
                fileInput.files = files
                // call the function to upload the files
                setFilesToUpload(files.slice(0, max_files))
            }
        })
    }, [])
    return (
        <div className='w-full'>
            <div className='column gap-2 mb-2'>
                <div className="input-container" id={`file-input-${item_index}`}>
                    <input className="input" type="file" multiple style={{ display: "none" }} id={`file-tag-${item_index}`} onChange={(e) => {
                        let files = e.target.files
                        files = [...files]
                        if (files.length > 0) {
                            setFilesToUpload(files.slice(0, max_files))
                        }
                    }} />

                    <div className="file-input-container cursor-pointer" id={`file-input-container-${item_index}`} onClick={() => {
                        document.getElementById(`file-tag-${item_index}`).click()
                    }}>
                        <MdUploadFile size={20} color={"black"} />
                        <p className="file-input-label">{filesToUpload.length > 0 ? `${filesToUpload.length} document attached` : text ? text : "Upload Document"}</p>
                        <p style={{ fontSize: 10 }}>{subtext ? subtext : `Maximum Upload Size: ${max_file_size} GB | Maximum Number of Files: ${max_files}`}</p>
                    </div>
                </div>
            </div>
            {filesToUpload.length > 0 &&
                <>
                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 10, marginTop: 10 }}>
                        {filesToUpload.map((file, index) => {
                            return (
                                <div style={{
                                    maxWidth: 200,
                                    backgroundColor: "gray",
                                    display: "flex",
                                    gap: 10,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: 5
                                }}
                                    onClick={() => {
                                        let newFilesToUpload = filesToUpload
                                        newFilesToUpload.splice(index, 1)
                                        setFilesToUpload([...newFilesToUpload])
                                    }}
                                >
                                    <p style={{
                                        fontSize: 12,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        color: "white"
                                    }}>{file.name}</p>
                                </div>
                            )
                        })}
                    </div>
                </>
            }

            {filesToUpload.length > 0 && <div className="flex-row flex gap-2 right w-100">
                <button className="backgroundLess row gap-2 items-center" onClick={() => {
                    submitDocuments()
                }}>
                    <IoCloudUpload size={20} />
                    Upload</button>
            </div>}
        </div>
    )
}
