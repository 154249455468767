import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import { Helmet } from "react-helmet";

const Whatsapp = () => {
  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>Whatsapp - Bedok Methodist Church</title>
        </Helmet>
        <div
          style={{
            position: "relative",
            background: "url('Whatsapp.jpeg')",
            minHeight: 250,
            backgroundSize: "cover",
            backgroundPosition: "50% 20%",
            backgroundColor: "#888888",
            backgroundBlendMode: "multiply",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h1
            className="whatsapp-cta"
            style={{
              fontWeight: 600,
              color: "#ffffff",
              width: "50%",
              margin: "auto",
              textAlign: "center",
              position: "absolute",
              right: 0,
              padding: 20,
            }}
          >
            JOIN OUR TELEGRAM & WHATSAPP CHANNELS
          </h1>
        </div>
        <div
          className="mode-of-contact"
          style={{ background: "#ffffff", padding: 20 }}
        >
          <div>
            <div>
              <img
                style={{ maxWidth: 200, margin: "auto", marginBottom: 30 }}
                width="100%"
                src="https://bmc.org.sg/wp-content/uploads/web_telegram1.jpg"
                alt=""
              />
            </div>
            <div style={{ fontWeight: "bold", color: "#777777" }}>
              <h3 style={{ fontWeight: "bold", color: "#555555" }}>
                <span>
                  <strong>BedokMC Telegram Channel</strong>
                </span>
              </h3>
              <ul
                style={{
                  width: "90%",
                  margin: "auto",
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                }}
              >
                <li>
                  <span>
                    <strong>
                      Download the free Telegram app from Apple App Store or
                      Google Play Store.
                    </strong>
                  </span>
                </li>
                <li>
                  <span>
                    <strong>
                      In your Telegram app, search for ‘Bedok Methodist Church’
                      and join channel.
                    </strong>
                  </span>
                </li>
                <li>
                  <span>
                    <strong>
                      OR click on the direct link below to join the channel.
                    </strong>
                  </span>
                </li>
              </ul>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a
                  style={{
                    textDecoration: "none",
                    marginBlock: 30,
                    display: "inline-block",
                    border: "1px solid #0099ab",
                    color: "#ffffff",
                    backgroundColor: "#0099ab",
                    paddingBlock: 10,
                    paddingInline: 20,
                    borderRadius: 10,
                    fontWeight: "bold",
                  }}
                  href="https://t.me/bedokmc"
                >
                  t.me/bedokmc
                </a>
              </div>
            </div>
          </div>

          <div>
            <div>
              <img
                style={{ maxWidth: 200, margin: "auto", marginBottom: 30 }}
                width="100%"
                src="https://bmc.org.sg/wp-content/uploads/web_whatsapp1.jpg"
                alt=""
              />
            </div>
            <img
              width="564"
              style={{ margin: "auto" }}
              src="https://bmc.org.sg/wp-content/uploads/BMC_Whatsapp2-564x800.jpg"
              alt=""
            />
          </div>
        </div>
        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default Whatsapp;
