import React from 'react'
import Header from '../../components/Header'
import { MdCancel, MdCheckCircle } from 'react-icons/md'

export default function TithingStatusPage() {
  let type = new URLSearchParams(window.location.search).get("type")

  return (
    <div>
      <Header/>
      <div className="column items-center center">
        {type === "success" ? (
          <MdCheckCircle size={100} color="green" />
        )
          : (
            <MdCancel size={100} color="red" />
          )}

        <p className="medium">{type === "success" ? "Tithing Successful" : "Tithing Failed"}</p>
      </div>
    </div>
  )
}
